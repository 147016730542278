@media (max-width: 1200px) {
  .melbourne-content-container {
    width: 1200px;
    max-width: 1200px;
    overflow-x: auto;
    box-sizing: border-box;
  }

  .melbourne-section-3 {
    min-height: 620px;
  }

  .melbourne-section-7 {
    height: 620px;
  }

  body,
  html {
    overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  li {
    color: #2c2c2c !important;
    font-family: 'Roboto', sans-serif !important;
  }

  p, li {
      line-height: 1.5rem !important;
    }
    

  p,
  a,
  li {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
    letter-spacing: normal !important;
  }

  h1 {
    font-size: 1.5rem !important;
    /* Reduced by 15% */
  }

  h2 {
    font-size: 1.2rem !important;
    /* Reduced by 15% */
  }

  h3 {
    font-size: 1.18rem !important;
    /* Reduced by 15% */
  }

  h4 {
    font-size: 1.1rem !important;
    /* Reduced by 15% */
  }

  h5 {
    font-size: 1rem !important;
    /* Reduced by 15% */
    margin-bottom: -15px;
    margin-top: 20px;
  }

  h6 {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
  }
}

.melbourne-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}

.melbourne-intro-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 165px;
  width: calc(100% - 165px);
  flex-grow: 1;
  position: relative;
  height: 400px;
  justify-content: space-between;
  z-index: 2;
  margin-top: 70px;
  margin-bottom: 80px;
}

.melbourne-intro-section-add {
  margin-top: 133px;
}

.melbourne-intro-section-text-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  position: relative;
  flex: 1;
  z-index: 2;
}

.melbourne-intro-section-text-content h1 {
  font-size: 2.5rem;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0s;
  position: relative;
  margin-bottom: 10px;
}

.melbourne-intro-section-text-content::after {
  content: '';
  display: block;
  width: calc(100% + 20px);
  max-height: 15px;
  min-height: 15px;
  position: relative;
  flex: 1;
  z-index: 99;
  left: 0px;
  background-color: rgb(0, 0, 128);
  margin-left: -40px;
  margin-top: 30px;
}

.melbourne-intro-section-text-content p {
  position: relative;
  color: #555555;
  transform: translateY(0px);
  margin-right: 20px;
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0.125s;
  margin-bottom: 10px;
}

.melbourne-intro-section-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.melbourne-intro-section-image-container img {
  width: 95%;
  height: auto;
  height: 400px;
  object-fit: cover;
  position: relative;
  border: 2px solid #E8E8E8;
  border-right: none;
  border-bottom: rgb(0, 0, 108) solid 2px;
  box-sizing: border-box;
}

.melbourne-section-4 {
  display: flex;
  min-height: 220px;
  position: relative;
  margin-left: 200px;
  width: calc(100% - 200px);
  justify-content: space-between;
  left: 0;
  z-index: 2;
  flex-direction: row;
  background: linear-gradient(135deg, #f9f9f9, #e9ecef);
  align-items: stretch;
  box-sizing: border-box;
  margin-bottom: 80px;
  z-index: 0;
}

.melbourne-section-4-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.melbourne-section-4-image-container img {
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
  /* cursor: pointer; */
}

.melbourne-section-4-image-card {
  position: relative;
  display: flex;
  border-top: rgb(0, 0, 128) 3px solid;
  border-bottom: rgb(0, 0, 128) 3px solid;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-right: whitesmoke solid 1.5px;
  box-sizing: border-box;
  transition: background-color 0.4s ease, opacity 0.4s ease;
  flex: 1;
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  height: 220px;
  width: 100%;
  overflow: hidden;
  /* cursor: pointer; */
}

.melbourne-section-4-image-card:nth-child(1) .melbourne-section-4-image-container img {
  transition: transform 1s ease;
  cursor: pointer;
  transform: translateY(-30px) translateX(0px) scale(1.3);
}

.melbourne-section-4-image-card:nth-child(1):hover .melbourne-section-4-image-container img {
  transform: translateY(-20px) translateX(20px) scale(1.9);
}

.melbourne-section-4-image-card:nth-child(2) .melbourne-section-4-image-container img {
  transition: transform 1s ease;
  cursor: pointer;
  transform: translateY(-30px) translateX(0px) scale(1);
}

.melbourne-section-4-image-card:nth-child(2):hover .melbourne-section-4-image-container img {
  transform: translateY(-60px) translateX(5px) scale(1.6);
}

.melbourne-section-4-image-card:nth-child(3) .melbourne-section-4-image-container img {
  transition: transform 1s ease;
  cursor: pointer;
  transform: translateY(-50px) translateX(0px) scale(1.3);
}

.melbourne-section-4-image-card:nth-child(3):hover .melbourne-section-4-image-container img {
  transform: translateY(-40px) translateX(0px) scale(1.9);
}

.melbourne-section-4-image-card:nth-child(4) .melbourne-section-4-image-container img {
  transition: transform 1s ease;
  cursor: pointer;
  transform: translateY(-60px) translateX(20px) scale(1.6);
}

.melbourne-section-4-image-card:nth-child(4):hover .melbourne-section-4-image-container img {
  transform: translateY(-60px) translateX(40px) scale(2.2);
}

.melbourne-section-4-image-card:nth-child(5) .melbourne-section-4-image-container img {
  transition: transform 1s ease;
  cursor: default;
  transform: translateY(-50px) translateX(0px) scale(1.2);
}

.melbourne-section-4-image-card:nth-child(5):hover .melbourne-section-4-image-container img {
  transform: translateY(-20px) translateX(-40px) scale(1.8);
}

.melbourne-section-4-image-card:nth-child(6) .melbourne-section-4-image-container img {
  transition: transform 1s ease;
  cursor: default;
  transform: translateY(-40px) translateX(-30px) scale(1.5);
}

.melbourne-section-4-image-card:nth-child(6):hover .melbourne-section-4-image-container img {
  transform: translateY(-40px) translateX(-40px) scale(2.1);
}

.melbourne-section-4-image-card:nth-child(7) .melbourne-section-4-image-container img {
  transition: transform 1s ease;
  cursor: default;
  transform: translateY(-40px) translateX(30px) scale(1.6);
}

.melbourne-section-4-image-card:nth-child(7):hover .melbourne-section-4-image-container img {
  transform: translateY(-25px) translateX(50px) scale(2.2);
}

.melbourne-section-4-image-card:last-child {
  border-right: none;
}

.melbourne-section-4-imagecard-overlay-white {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: white;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  clip-path: polygon(0 30%, 100% 0%, 100% 100%, 0% 100%);
  transition: clip-path 0.6s ease-in-out;
  z-index: 2;
  cursor: default;
}

.melbourne-section-4-image-card:hover .melbourne-section-4-imagecard-overlay-white {
  clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0% 100%);
}

.melbourne-section-4-imagecard-overlay-white h6 {
  padding: 0;
  color: #343a40;
  font-size: .8rem !important;
  transition: transform 1s ease;
  position: relative;
  bottom: -10px;
  text-align: center;
  line-height: 1.2;
}

.melbourne-section-4-imagecard-overlay-black {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45%;
  background-color: #343a40;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  clip-path: polygon(0 0%, 100% 20%, 100% 100%, 0% 100%);
  transition: clip-path 0.6s ease-in-out;
  z-index: 2;
}

.melbourne-section-4-image-card:hover .melbourne-section-4-imagecard-overlay-black {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  background-color: rgb(0, 0, 108);
}

.melbourne-section-4-imagecard-overlay-black h6 {
  margin: 10px;
  padding: 0;
  color: white !important;
  font-size: .8rem !important;
  transition: transform 1s ease;
  text-align: left;
  position: absolute;
  bottom: 3px;
  line-height: 1.2;
  left: 10px;
}



@media screen and (max-width: 768px) {
  .melbourne-intro-section-text-content {
    width: 100%;
    margin: 0 auto;
    min-height: auto;
    padding: 10px 0;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .melbourne-intro-section-text-content h1 {
    text-align: left;
    position: relative;
    left: 18px;
    margin-bottom: 10px;
  }

  .melbourne-intro-section-text-content::after {
    display: none;
  }

  .melbourne-intro-section-text-content p {
    position: relative;
    left: 0;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    margin-bottom: 0px;
  }




  .melbourne-section-4 {
    display: grid;
    /* Enable grid layout */
    grid-template-columns: repeat(2, 1fr);
    /* Two items per row */
    gap: 5px;
    /* Small gap between grid items */
    margin-left: 0;
    /* Remove fixed margin */
    width: 100%;
    /* Full width */
    margin-bottom: 20px;
    align-items: stretch;
    /* Ensure all grid items stretch to the same height */
  }

  .melbourne-section-4-image-container {
    height: 100%;
    /* Fill parent container */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .melbourne-section-4-image-container img {
    width: 100%;
    /* Keep images responsive */
    height: 100%;
    /* Stretch to fill container */
    object-fit: cover;
    /* Maintain aspect ratio and crop if needed */
  }

  .melbourne-section-4-image-card {
    height: 200px;
    /* Set a uniform height for all cards */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Ensure full width */
    margin: 0;
    /* Remove margin */
    border-right: none;
    /* Remove border for cleaner look */
    border: none;
  }


  .melbourne-section-4-image-card:nth-child(6) .melbourne-section-4-image-container img {
    transition: transform 1s ease;
    cursor: default;
    transform: translateY(-40px) translateX(20px) scale(1.5);
  }

  .melbourne-section-4-image-card:nth-child(6):hover .melbourne-section-4-image-container img {
    transform: translateY(-40px) translateX(30px) scale(2.1);
  }

  .default-section-content-left {
    flex-direction: column; /* Stack text and image vertically */
    margin-left: 0; /* Remove desktop margin */
    width: 100%; /* Full-width for mobile */
    align-items: center;
    border-bottom: none; 
    margin: 0 0 20px;
  }

  .default-section-content-left {
    flex-direction: column; 
    margin-left: 0; 
    width: 100%; 
    align-items: center;
    border-bottom: none; 
  }

  .default-section-content-left-text-content {
    left: 0; /* Align text to the container */
    margin-left: 20px;
    margin-right: 20px;
    text-align: left; /* Center-align text for mobile */
    flex: none; /* Remove flex sizing for stacking */
    margin-bottom: 10px; 
  }

  

  .default-section-content-left-text-content ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: rgb(0, 0, 128);
    line-height: 1.5rem !important;
  }
  
  .default-section-content-left-image-container {
    /* flex: none;  */
    flex-direction: column;
    align-items: stretch;
    width: 100%; /* Make image container full width */
    justify-content: center; /* Center the image */
    margin-top: 5px;
    box-sizing: border-box;
  }





  .default-section-content-left-image-container img {
    width: 100%; /* Scale image for mobile */
    max-height: 165px; /* Limit height for smaller screens */
    border: none;
    object-fit: cover;
    /* margin-bottom: -50px; */
  }

  .express-faq-section,
  .dg-faq-section,
  .local-faq-section,
  .general-faq-section,
  .project-faq-section,
  .air-faq-section,
  .rail-faq-section,
  .road-faq-section,
  .pallet-faq-section,
  .melbourne-faq-section,
  .container-faq-section,
  .carton-faq-section,
  .crate-faq-section,
  .satchel-faq-section,
  .envelope-faq-section,
  .drum-faq-section,
  .ibc-faq-section,
  .panel-faq-section,
  .stillage-faq-section,
  .integration-faq-section,
  .bdouble-faq-section,
  .semi-faq-section {
    flex-direction: column; /* Stack content vertically */
    align-items: left; /* Center-align for mobile */
    margin: -5px 20px 17px; /* Add 20px margin on left and right */
    width: calc(100% - 40px); /* Account for left and right margins */
    height: auto; /* Let height adjust based on content */
    justify-content: flex-start;
    box-sizing: border-box; /* Ensure padding/margin fits inside width */
    
  }

  

  .faq-text-content {
    left: 0; /* Remove left positioning */
    margin: 0; /* Reset all margins */
    text-align: left; /* Center-align text */
    width: 100%; /* Full width within the container */
    box-sizing: border-box; /* Ensure padding fits inside width */
  }

  .faq-text-content h2 {
    margin-bottom: 20px; /* Add spacing below the heading */
  }

  .faq-item {
    width: 100%; /* Full width for FAQ items */
    margin-bottom: 15px; /* Reduce spacing between items */
    padding-bottom: 15px; /* Reduce padding */
    border-bottom: 1px solid #ccc;
    box-sizing: border-box; /* Ensure padding fits inside width */
  }

  .faq-item h5 {
    padding: 10px 0; /* Add more padding for better touch targets */
  }

  .faq-content {
    max-height: 0; /* Default hidden */
    opacity: 0; /* Default hidden */
    transition: max-height 0.4s ease, opacity 0.2s ease;
  }

  .faq-item.active .faq-content {
    max-height: 200px; /* Expand when active */
    opacity: 1; /* Fully visible */
  }

  .faq-content p,
  .faq-content ul,
  .faq-content li,
  .faq-content a {
    line-height: 1.5; /* Adjust line height */
    text-align: left; /* Left-align content for clarity */
  }

  .sydney-add{
    margin-top: 15px;
  }

}