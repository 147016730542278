@media (max-width: 1200px) {
    .home-content-container-parent {
        width: 1200px;
        max-width: 1200px;
        overflow-x: auto;
        box-sizing: border-box;
    }

    .carousel-image {
        width: 1200px;
        max-width: 1200px;
        overflow: hidden;
    }

    .carousel-container {
        width: 1200px;
        max-width: 1200px;
        overflow: hidden;
    }
}

@media screen and (max-width: 768px) {
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    div,
    li {
      color: #2c2c2c !important;
      font-family: 'Roboto', sans-serif !important;
    }

    p, li {
        line-height: 1.5rem !important;
      }
      
  
    p,
    a,
    li {
      font-size: 0.86rem !important;
      /* Reduced by 15% */
      letter-spacing: normal !important;
    }
  
    h1 {
      font-size: 1.5rem !important;
      /* Reduced by 15% */
    }
  
    h2 {
      font-size: 1.3rem !important;
      /* Reduced by 15% */
    }
  
    h3 {
      font-size: 1.2rem !important;
      /* Reduced by 15% */
    }
  
    h4 {
      font-size: 1.1rem !important;
      /* Reduced by 15% */
    }
  
    h5 {
      font-size: 1rem !important;
      /* Reduced by 15% */
      margin-bottom: -15px;
      margin-top: 20px;
    }
  
    h6 {
      font-size: 0.86rem !important;
      /* Reduced by 15% */
    }
  }

.carousel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    object-fit: cover;
}

.carousel-container {
    z-index: 99;
    width: 100%;
    height: 100vh;
    position: relative;
    margin-top: 0;
    overflow: hidden;
}

.carousel-arrow-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white !important;
    font-size: 2rem !important;
    cursor: pointer;
    z-index: 100;
    animation: scaleUpAndDown 2.5s ease-in-out infinite;
    transition: transform 0.5s ease-in-out;
}

/* @keyframes scaleUpAndDown {
    0% {
        transform: translateX(-50%) scale(1);
    }

    50% {
        transform: translateX(-50%) scale(1.4);
    }

    100% {
        transform: translateX(-50%) scale(1);
    }
} */

.carousel-arrow-down:hover {
    animation: none;
    transform: translateX(-50%) scale(1.4);
    /* Enlarge on hover */
}

.home-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 100vh;
    box-sizing: border-box;
}

.home-section-2 {
    display: flex;
    min-height: 400px;
    max-height: 400px;
    position: relative;
    left: 0;
    z-index: 2;
    flex-direction: row;
    background: linear-gradient(135deg, #e9ecef, #dee2e6);
    align-items: stretch;
    box-sizing: border-box;
    margin-bottom: 80px;
}

.home-section-2-image-card {
    position: relative;
    display: flex;
    border-bottom: rgb(0, 0, 128) 3px solid;
    border-top: rgb(0, 0, 128) 3px solid;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-right: #E8E8E8 solid 1px;
    box-sizing: border-box;
    transition: background-color 0.4s ease, opacity 0.4s ease;
    flex: 1;
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    height: 400px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.home-section-2-image-card img {
    object-fit: cover;
    object-position: center;
    transition: transform 1s ease;
    cursor: pointer;
}

.home-section-2-image-card:nth-child(1) img {
    object-position: 30%;
    transform: translateY(-10%);
    width: 100%;
    height: 100%;
}

.home-section-2-image-card:nth-child(1):hover img {
    transform: translateX(20px) translateY(-70px) scale(1.6);
    cursor: pointer;
}

.home-section-2-image-card:nth-child(2) img {
    object-position: 50%;
    transform: translateY(-20%) translateX(0px);
    width: 100%;
    height: 100%;
}

.home-section-2-image-card:nth-child(2):hover img {
    transform: translateX(-40px) translateY(-40px) scale(1.3);
    cursor: pointer;
}

.home-section-2-image-card:nth-child(3) img {
    object-position: 50%;
    transform: translateY(-20%);
    width: 100%;
    height: 100%;
}

.home-section-2-image-card:nth-child(3):hover img {
    transform: translateX(0px) translateY(-100px) scale(1.6);
    cursor: pointer;
}

.home-section-2-image-card:nth-child(4) img {
    object-position: 40%;
    transform: translateY(-20%) translateX(0px);
    width: 100%;
    height: 100%;
}

.home-section-2-image-card:nth-child(4):hover img {
    transform: translateX(-30px) translateY(-180px) scale(1.6);
    cursor: pointer;
}

.home-section-2-image-card:last-child {
    border-right: none;
}

.home-section-2-image-card:hover .filter-overlay {
    opacity: 0;
}

.home-section-2-image-card:hover .home-section-2-imagecard-overlay-div {
    clip-path: polygon(0 39%, 100% 75%, 100% 100%, 0% 100%);
    background-color: rgb(0, 0, 108);
    cursor: pointer;
}

.home-section-2-image-card:hover .home-section-2-imagecard-overlay-divtwo {
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0% 100%);
    cursor: pointer;
}

.home-section-2-image-card:hover h4 {
    transform: translateY(3.5rem);
    position: bottom;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
}

.home-section-2-image-card:hover .home-section-2-imagecard-overlay-div p {
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    transition-delay: 0s;
}

.home-section-2-image-card:hover .home-section-2-imagecard-overlay-div .learn-more-link {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

.home-section-2-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.home-section-2-image-container .filter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    transition: opacity 0.5s ease;
    pointer-events: none;
    z-index: 1;
}

.home-section-2-imagecard-overlay-div {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 45%;
    background-color: #343a40;
    color: white;
    display: flex;
    box-sizing: border-box;
    z-index: 2;
    clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
    transition: clip-path 0.6s ease-in-out;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
}

.home-section-2-imagecard-overlay-div .learn-more-link {
    opacity: 0;
    visibility: hidden;
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
    transition: opacity 0.4s ease, visibility 0.4s ease;
    display: inline-block;
    position: relative;
    bottom: 15px;
    left: 10px;
    text-align: left;
    cursor: pointer;
}

.home-section-2-imagecard-overlay-div p {
    color: white !important;
    font-size: 1rem;
    text-align: left;
    width: 90%;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    transition-delay: 0.3s;
    visibility: visible;
    cursor: pointer;
    position: relative;
    left: 10px;
}

.home-section-2-imagecard-overlay-div h4 {
    margin: 0;
    padding: 0;
    color: white !important;
    text-align: left;
    width: 220px;
    font-size: 1.5rem;
    transition: transform .75s ease;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 10px;
}

.home-section-2-imagecard-overlay-divtwo {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1;
    clip-path: polygon(0 50%, 100% 30%, 100% 100%, 0% 100%);
    transition: clip-path 0.7s ease-in-out;
    cursor: pointer;
}

.home-section-5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 125px;
    width: calc(100% - 125px);
    flex-grow: 1;
    position: relative;
    height: 560px;
    justify-content: space-between;
    background: #2980b9;
    background: url('../../public/images/qfm-adelaide-logistics-services.jpg') no-repeat center center fixed;
    background-size: cover;
    overflow: hidden;
    margin-top: -80px;
}

.home-section-5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.9;
    /* Adjust the opacity as needed */
    z-index: 1;
}

.home-section-5-text-content {
    position: relative;
    top: 50px;
    flex: 1;
    z-index: 2;
}

.home-section-5-text-content h2 {
    font-size: 2rem;
}

.home-section-5-text-content ul {
    list-style-type: none;
    font-size: 1rem;
}

.home-section-5-text-content ul li {
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 1.7;
    color: #555555;
}

.home-section-5-text-content ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    font-size: 2rem;
    color: rgb(0, 0, 128);
    line-height: .9;
}

.info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    margin: 0 auto;
    /* Ensure centering if flexbox isn't applied on the parent */
    width: 100%;
    max-width: 800px;
    padding: 10px 10px 0 0;
    margin-bottom: 15px;
}

.info-icon {
    width: 75px;
    height: 75px;
    margin: 10px 20px;
    position: relative;
    top: -25px;
    left: -5px;
}

.info-container h3 {
    font-size: 1.5rem;
    margin: 10px 0 -10px 0;
    text-align: left;
}

.info-container p {
    font-size: 1rem;
    text-align: left;
    line-height: 25px;
}



@media screen and (max-width: 768px) {
    .home-content-container-parent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100vw;
        height: auto;
        box-sizing: border-box;
    }

    .maincontent-one {
        display: none;
    }

    .default-intro-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-height: 87vh;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        margin: 0;
        overflow: hidden;
    }

    .default-intro-section img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        left: 0;
        z-index: 1;
        transform: scale(1.3);
    }

    .default-intro-section h1 {
        text-align: left;
        z-index: 2;
        position: relative;
        background-color: rgba(95, 95, 132, 0.5);
        width: 100%;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        bottom: 0px;
        font-size: 2rem !important;
    }

    .default-intro-section h1 span {
        display: block;
        color: rgb(0, 0, 108) !important;
        margin-bottom: 5px;
        position: relative;
        left: 19.5px;
    }

    .default-intro-section h1 span:nth-child(1) {
        -webkit-text-stroke: 1.2px rgb(0, 0, 95);
    }

    .default-intro-section h1 span:nth-child(2) {
        color: whitesmoke !important;
    }

    .default-intro-section h1 span:nth-child(3) {
        color: whitesmoke !important;
    }

    .home-content-spacer-container {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
    }

    .home-content-spacer-container h1 span{
        font-size: 2rem !important;
    }

    .home-section-2 {
        height: inherit;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        min-height: auto;
        max-height: auto;
        margin-bottom: 10px;
        margin-top: 5px;
        background: white;
    }

    .home-section-2-image-card {
        height: 200px;
        border: none;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .home-section-2-image-card img {
        width: 100%;
        /* Ensure image fills card */
        height: 100%;
        /* Keep aspect ratio */
        object-fit: cover;
        /* Cover the area */
    }

    .home-section-2-image-card:hover h4 {
        transform: translateY(0rem);
        position: bottom;
        cursor: pointer;
        transition: transform 0.6s ease-in-out;
    }
    
    .home-section-2-image-card:nth-child(1):hover img {
        transform: translateX(20px) translateY(-30px) scale(1.6);
        cursor: pointer;
    }
    
    .home-section-2-image-card:nth-child(2):hover img {
        transform: translateX(-20px) translateY(-40px) scale(1.3);
        cursor: pointer;
    }

    .home-section-2-image-card:nth-child(3):hover img {
        transform: translateX(0px) translateY(-50px) scale(1.6);
        cursor: pointer;
    }
    
    .home-section-2-image-card:nth-child(4):hover img {
        transform: translateX(-30px) translateY(-70px) scale(1.5);
        cursor: pointer;
    }




    .home-section-2-imagecard-overlay-div h4 {
        margin: 0;
        padding: 0;
        color: white !important;
        text-align: left;
        width: 220px;
        transition: transform .75s ease;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 12px;
        margin-bottom: 10px;
    }

    .australia-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .australia-section-text-content {
        position: static;
        top: auto;
        left: auto;
        width: 100%;
        margin: 0 auto;
    }

    .australia-section-image-container {
        position: static;
        top: auto;
        left: auto;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }

    .australia-section-image-container img {
        width: 100%;
        height: auto;
        position: static;
        top: auto;
    }

    .australia-section-text-content h2 {
        position: relative;
        left: 18.5px;
    }

    .australia-section-text-content ul {
        margin-left: 20px;
        margin-right: 20px;
        list-style-type: none !important;
    }
    .australia-section-text-content p{
        margin-left: 20px;
        margin-right: 20px;
      }

    australia-section-text-content li {
        list-style-type: none !important;
    }

    .australia-section-text-content ul li {
        line-height: 1.5;
        position: relative;
        list-style-type: none !important;
    }

    .australia-section-text-content ul li::before {
        display: none;
    }

    .default-quote {
        display: flex;
        flex-direction: column;
        align-items: stretch;        
        margin: 0;
        width: 100%;
        background-color: whitesmoke;
        box-sizing: border-box;
        border-left: none;
        border-right: none;
        box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05);
        border: none;
        margin-top: -1px;
    }

    .default-quote-text-content {
        position: static;
        margin: 0px 20px 0px 20px;
        flex: unset;
        line-height: 1.5rem !important;
    }

    .default-quote-text-content p {
        line-height: 1.4;
    }

    .default-quote-quote {
        flex: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #343a40;
        border-left: none;
        box-sizing: border-box;
        cursor: pointer;
        padding: 0;
        margin: 0; 
        z-index: 9999 !important;
        margin-top: -1px;
    }

    .default-quote-quote a {
        padding: 0;
        width: 100vw;
        height: 50px;
        /* background-color: pink; */
    }

    .default-quote h4 {
        z-index: 99999;
    }

    .home-section-5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
        height: auto;
        justify-content: center;
        background: url('../../public/images/qfm-home-mobile-main.png') no-repeat center center;
        background-size: cover;
        overflow: hidden;
        margin-top: 0;
    }

    .home-section-5::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.8;
        z-index: 1;
    }

    .home-section-5-text-content {
        position: relative;
        top: 5px;
        margin-bottom: 3px;
        flex: 1;
        z-index: 2;
    }

    .home-section-5-text-content ul {
        padding: 0;
        margin: 10px auto;
        list-style-type: none;
    }

    .home-section-5-text-content ul li {
        margin-bottom: 10px;
        line-height: 1.5;
        position: relative;
        color: #555555;
    }

    .home-section-5-text-content ul li::before {
        content: none;
    }

    .info-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        padding: 10px 10px 0 0;
        margin-bottom: 12px;
        margin-right: 12px;
    }

    .info-icon {
        width: 50px;
        height: 50px;
    }

    .info-container h3 {
        margin: 10px 0 -10px 0;
        text-align: left;
    }

    .info-container p {
        text-align: left;
        line-height: 25px;
        margin-right: 10px;
    }

}