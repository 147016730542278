@media (max-width: 1200px) {
  .getintouch-content-container {
    width: 1200px;
    max-width: 1200px;
    overflow-x: auto;
    box-sizing: border-box;
  }

  body,
  html {
    overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  li {
    color: #2c2c2c !important;
    font-family: 'Roboto', sans-serif !important;
  }

  p, li {
      line-height: 1.5rem !important;
    }
    

  p,
  a,
  li, 
  button,
  span {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
    letter-spacing: normal !important;
  }

  h1 {
    font-size: 1.5rem !important;
    /* Reduced by 15% */
  }

  h2 {
    font-size: 1.3rem !important;
    /* Reduced by 15% */
  }

  h3 {
    font-size: 1.2rem !important;
    /* Reduced by 15% */
  }

  h4 {
    font-size: 1.1rem !important;
    /* Reduced by 15% */
  }

  h5 {
    font-size: 1rem !important;
    /* Reduced by 15% */
    margin-bottom: -15px;
    margin-top: 20px;
  }

  h6 {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
  }
}

.getintouch-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}

.getintouch-intro-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 165px;
  width: calc(100% - 165px);
  flex-grow: 1;
  position: relative;
  height: 400px;
  justify-content: space-between;
  z-index: 2;
  margin-top: 70px;
  margin-bottom: 80px;
}

.getintouch-intro-section-add {
  margin-top: 133px;
}

.getintouch-intro-section-text-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  position: relative;
  flex: 1;
  z-index: 2;
}

.getintouch-intro-section-text-content h1 {
  font-size: 2.5rem;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0s;
  position: relative;
  margin-bottom: 10px;
}

.getintouch-intro-section-text-content::after {
  content: '';
  display: block;
  width: calc(100% + 20px);
  max-height: 15px;
  min-height: 15px;
  position: relative;
  flex: 1;
  z-index: 99;
  left: 0px;
  background-color: rgb(0, 0, 128);
  margin-left: -40px;
  margin-top: 30px;
}

.getintouch-intro-section-text-content p {
  position: relative;
  color: #555555;
  transform: translateY(0px);
  margin-right: 20px;
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0.125s;
  margin-bottom: 10px;
}

.getintouch-intro-section-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.getintouch-intro-section-image-container img {
  width: 95%;
  height: auto;
  height: 400px;
  object-fit: cover;
  position: relative;
  border: 2px solid #E8E8E8;
  border-right: none;
  border-bottom: rgb(0, 0, 108) solid 2px;
  box-sizing: border-box;
}






.getintouch-section-2 {
  margin-left: 200px;
  width: calc(100% - 200px);
  display: flex;
  position: relative;
  justify-content: space-between;
  left: 0;
  z-index: 0;
  flex-direction: row;
  background: linear-gradient(135deg, #f9f9f9, #e9ecef);
  align-items: stretch;
  box-sizing: border-box;
  margin-bottom: 70px;
}

.getintouch-section-2-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.getintouch-section-2-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1s ease;
  cursor: pointer;
}

.getintouch-section-2-image-card {
  position: relative;
  display: flex;
  border-top: rgb(0, 0, 128) 2px solid;
  border-bottom: rgb(0, 0, 128) 2px solid;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-right: whitesmoke solid 1.5px;
  box-sizing: border-box;
  transition: background-color 0.4s ease, opacity 0.4s ease;
  flex: 1;
  opacity: 1;
  transform: translateY(0px);
  height: 130px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;

}

.getintouch-section-2-image-container img {
  object-fit: scale-down;
  transition: transform 1s ease;
  cursor: pointer;
  transform: translateY(-20px);
}

.getintouch-section-2-image-container:hover img {
  transform: translateY(-50px) translateX(0px) scale(1.4);
}

.getintouch-section-2-image-card:last-child {
  border-right: none;
}

.getintouch-section-2-imagecard-overlay-white {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: white;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  clip-path: polygon(0 50%, 100% 35%, 100% 100%, 0% 100%);
  transition: clip-path 0.5s ease-in-out;
  z-index: 2;
}

.getintouch-section-2-image-card:hover .getintouch-section-2-imagecard-overlay-white {
  clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0% 100%);
}

.getintouch-section-2-imagecard-overlay-white h6 {
  padding: 0;
  color: #343a40 !important;
  font-size: 0.8rem !important;
  position: relative;
  bottom: 6px;
  text-align: center;
  line-height: 1.2;
  margin: 2px;
}

.getintouch-section-2-imagecard-overlay-black {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45%;
  background-color: rgb(0, 0, 90);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  clip-path: polygon(0 35%, 100% 55%, 100% 100%, 0% 100%);
  transition: clip-path 0.5s ease-in-out;
  z-index: 2;
}

.getintouch-section-2-image-card:hover .getintouch-section-2-imagecard-overlay-black {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
}

.getintouch-section-2-imagecard-overlay-black h6 {
  margin: 2px;
  padding: 0;
  color: white !important;
  font-size: 0.8rem !important;
  position: relative;
  bottom: 8px;
  line-height: 1.2;
  text-align: center;
}

.getintouch-section-3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: auto;
  justify-content: space-between;
  margin-bottom: 85px;
}

.getintouch-section-3-text-content {
  position: relative;
  top: 10px;
  /* Adjust vertical position */
  margin: 0 auto;
  /* Center horizontally */
  display: flex;
  /* Use flexbox for alignment */
  justify-content: center;
  /* Align content horizontally */
  align-items: center;
  /* Align content vertically */
  text-align: center;
  /* Center-align text inside the container */
  width: fit-content;
  /* Adjust width dynamically based on content */
}

.getintouch-section-3-text-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555555;
}


.getintouch-section-3-text-content ul {
  list-style-type: none;
  font-size: 1rem;
}

.getintouch-section-3-text-content ul li {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.7;
  color: #555555;
  position: relative;
  /* Ensures the ::before works correctly */
  padding-left: 20px;
  /* Spacing for the bullet */
}

.getintouch-section-3-text-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 2rem;
  color: rgb(0, 0, 128);
  line-height: 0.9;
}



































/* Form Styles */
.contact-us-form {
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: auto;
  max-height: 420px;
  gap: 25px;
  position: relative;
  top: 25px;
  left: auto;
  margin-bottom: 110px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group.full-width {
  flex: 2;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.form-group label input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 10px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  /* border-radius: 5px; */
}

.form-group.radio-group {
  display: flex;
  align-items: left;
  font-size: 1.75rem;
}

.form-group.radio-group label {
  display: flex;
  align-items: center;
  margin-left: 0;
}

.form-group.radio-group input[type="radio"] {
  margin-right: 10px;
  margin-bottom: 3px;
  width: 15px;
  /* Adjust width */
  height: 15px;
  /* Adjust height */
  transform: scale(1.5);
  /* Scale the radio button (adjust the value as needed) */
  margin-right: 10px;
  /* Optional: Add spacing */
  margin-bottom: 3px;
  /* Optional: Add spacing */
  cursor: pointer;
  /* Makes it more user-friendly */
}




.form-row.three-items .form-group {
  flex: 1 1 30%;
  max-width: 154px;
}

.response-time {
  text-align: right;
  font-style: italic;
  color: #555;
}

.contact-us-form .input-error {
  border-color: #e74c3c !important;
}

.contact-us-form .label-error,
.contact-us-form .error {
  color: #e74c3c !important;
  font-size: 0.7em;
  margin-left: 5px;
}

/* Button Styles */
.button-row {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 100px;
}

.send-button,
.submit-button,
.next-button,
.previous-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
}

.send-button {
  background-color: #003f7d;
  /* Dark blue */
  align-self: flex-end;
  position: relative;
  bottom: 12px;
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover,
.submit-button:hover {
  background-color: #002a5c;
  /* Darker blue on hover */
}

.send-button:active {
  background-color: #001b3f;
  /* Deepest blue on active */
}

.next-button,
.submit-button {
  background-color: #003f7d;
  /* Same dark blue for uniformity */
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.previous-button {
  background-color: #3a4d63;
  /* Neutral darker gray-blue */
  margin-right: 10px;
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.next-button:hover,
.previous-button:hover {
  background-color: #002a5c;
  /* Darker blue on hover for all */
}

.next-button:active,
.previous-button:active,
.submit-button:active {
  background-color: #001b3f;
  /* Deepest blue on active */
}

.submit-button {
  background-color: #003f7d;
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.right-aligned {
  margin-left: auto;
}

/* Step Indicator Styles */
.step-indicator {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: left;
}

/* Custom Alert Styles */
.custom-alert {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  /* border-radius: 5px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  max-width: 80%;
  padding: 20px;
}

.custom-alert.success {
  border-color: #28a745;
  background-color: #d4edda;
  color: #155724;
}

.custom-alert.error {
  border-color: #dc3545;
  background-color: #f8d7da;
  color: #721c24;
}

.custom-alert-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.custom-alert-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.custom-alert-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.custom-alert-body {
  font-size: 1rem;
}






@media screen and (max-width: 768px) {
  .getintouch-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
  }

  .getintouch-intro-section {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    text-align: left;
    margin-left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .getintouch-intro-section-add {
    margin-top: 50px;
  }

  .getintouch-intro-section-text-content {
    width: 100%;
    margin: 0 auto;
    min-height: auto;
    padding: 10px 0;
    margin-bottom: 10px;
  }

  .getintouch-intro-section-text-content h1 {
    /* Reduced by 15% */
    text-align: left;
    position: relative;
    left: 18px;
    margin-bottom: 10px;
  }

  .getintouch-intro-section-text-content::after {
    display: none;
  }

  .getintouch-intro-section-text-content p {
    position: relative;
    left: 0;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;

  }

  .getintouch-intro-section-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
    box-sizing: border-box;
  }

  .getintouch-intro-section-image-container img {
    width: 100%;
    height: auto;
    max-height: 250px;
    margin-top: -10px;
    object-fit: cover;
    position: relative;
    border: none;
    box-sizing: border-box;
  }

  .getintouch-section-3-text-content p {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
    line-height: 1.4;
    color: #555555;
    margin-bottom: 10px;
  }

  .getintouch-section-3-text-content ul {
    list-style-type: none;
    /* Adjust font size */
    margin: 0;
    padding: 0;
    /* Remove default margin/padding */
  }

  .getintouch-section-3-text-content ul li {
    font-size: 0.9rem;
    line-height: 1.5;
    /* Adjust line spacing */
    color: #555555;
    position: relative;
    /* Ensure bullet is positioned correctly */
    padding-left: 15px;
    /* Adjust padding for bullets */
  }

  .getintouch-section-3-text-content ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    font-size: 1.5rem;
    color: rgb(0, 0, 128);
    line-height: 1;
  }


  .form-group.radio-group {
    display: none;
    align-items: left;
  }

  .form-group.radio-group label {
    display: flex;
    align-items: center;
    margin-left: 0;
  }



  .getintouch-section-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    width: 100%;
    justify-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 160px;
    margin-top: -20px;
    height: auto; 
    /* overflow: hidden;  */
}

.getintouch-section-3-text-content {
    position: relative; /* Allow the element to influence parent's height */
    top: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    padding: 0; /* Ensure no extra padding pushes content */
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust width for responsiveness */
  max-width: 100%; /* Prevent it from exceeding container */
  height: auto; /* Allow dynamic height */
  gap: 20px; /* Maintain consistent spacing */
  box-sizing: border-box;
  margin-left: 11px;
  margin-right: 11px;
}

/* Stack rows vertically for mobile */
.form-row {
  display: flex;
  flex-direction: column; /* Stack inputs vertically */
  gap: 15px; /* Adjust gap between rows */
}

/* Adjust form groups */
.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group.full-width {
  flex: 1; /* Full width for single input */
}

/* Adjust label styles */
.form-group label {
  margin-bottom: 8px; /* Slightly increase space for clarity */
  font-weight: bold;
  text-align: left;
}

.form-group label input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 8px;
}

/* Style inputs, selects, and textareas */
.form-group input,
.form-group select,
.form-group textarea {
  padding: 12px; /* Increase padding for touch targets */
  font-size: 0.9rem; /* Smaller font size for compactness */
  border: 1px solid #ccc;
  /* border-radius: 4px;  */
}

/* Adjust textareas */
.form-group textarea {
  min-height: 100px; /* Ensure enough height for text entry */
  resize: vertical; /* Allow resizing only vertically */
}

/* Add focus styles for better visibility */
.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #2980b9; /* Highlight border on focus */
  box-shadow: 0 0 5px rgba(41, 128, 185, 0.5); /* Subtle glow effect */
}

/* Optional: Adjust spacing for smaller screens */
  .contact-us-form {
    gap: 15px; /* Slightly reduce gap for smaller screens */
    /* margin-bottom: 30px; */
  }

  .form-row {
    gap: 10px;
  }

  .form-group label {
    font-size: 0.85rem; 
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 0.85rem;
    padding: 10px; 
  }

  .send-button,
  .submit-button,
  .next-button,
  .previous-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: white;
  }


  .response-time {
    text-align: right;
    font-style: italic;
    color: #555;
    margin-bottom: 10px;
  }

  .step-indicator {
    font-size: 1rem;
    margin-bottom: 0px;
    text-align: left;
    line-height: 1.4;
  }


}