@media (max-width: 1280px) {
  .ourstory-content-container {
    width: 1280px;
    max-width: 1280px;
    overflow-x: auto;
    box-sizing: border-box;
  }

  body,
  html {
    overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  li {
    color: #2c2c2c !important;
    font-family: 'Roboto', sans-serif !important;
  }

  p, li {
      line-height: 1.5rem !important;
    }
    

  p,
  a,
  li {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
    letter-spacing: normal !important;
  }

  h1 {
    font-size: 1.5rem !important;
    /* Reduced by 15% */
  }

  h2 {
    font-size: 1.3rem !important;
    /* Reduced by 15% */
  }

  h3 {
    font-size: 1.2rem !important;
    /* Reduced by 15% */
  }

  h4 {
    font-size: 1.1rem !important;
    /* Reduced by 15% */
  }

  h5 {
    font-size: 1rem !important;
    /* Reduced by 15% */
    margin-bottom: -15px;
    margin-top: 20px;
  }

  h6 {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
  }
}

.ourstory-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}

.ourstory-intro-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 165px;
  width: calc(100% - 165px);
  flex-grow: 1;
  position: relative;
  height: 400px;
  justify-content: space-between;
  z-index: 2;
  margin-top: 70px;
  margin-bottom: 80px;
}

.ourstory-intro-section-add {
  margin-top: 133px;
}

.ourstory-intro-section-text-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  position: relative;
  flex: 1;
  z-index: 2;
}

.ourstory-intro-section-text-content h1 {
  font-size: 2.5rem;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0s;
  position: relative;
  margin-bottom: 10px;
  /* left: 40px; */
}

.ourstory-intro-section-text-content::after {
  content: '';
  display: block;
  width: calc(100% + 20px);
  max-height: 15px;
  min-height: 15px;
  position: relative;
  flex: 1;
  z-index: 99;
  left: 0px;
  background-color: rgb(0, 0, 128);
  margin-left: -40px;
  margin-top: 30px;
}

.ourstory-intro-section-text-content p {
  position: relative;
  color: #555555;
  transform: translateY(0px);
  margin-right: 20px;
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0.125s;
  margin-bottom: 10px;
}

.ourstory-intro-section-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 0px;
  margin-top: 5px;
}

.ourstory-intro-section-image-container img {
  width: 95%;
  height: auto;
  height: 400px;
  object-fit: cover;
  position: relative;
  border: 2px solid #E8E8E8;
  border-right: none;
  border-bottom: rgb(0, 0, 108) solid 2px;
  box-sizing: border-box;
}





.ourstory-section-3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: auto;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: -10px;
}

.ourstory-section-3-text-content {
  position: relative;
  top: -30px;
  left: 40px;
  flex: 1.2;
  margin-right: 100px;
}

.ourstory-section-3-text-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555555;
}

.ourstory-section-3-text-content h2 {
  font-size: 2rem;
}

.ourstory-section-3-text-content ul {
  list-style-type: none;
  font-size: 1rem;
}

.ourstory-section-3-text-content ul li {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.7;
  color: #555555;
  position: relative;
  /* Ensures the ::before works correctly */
  padding-left: 20px;
  /* Spacing for the bullet */
}

.ourstory-section-3-text-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 2rem;
  color: rgb(0, 0, 128);
  line-height: 0.9;
}


@media screen and (max-width: 768px) {
  .ourstory-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
  }

  .ourstory-intro-section {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    text-align: left;
    margin-left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    
  }

  .ourstory-intro-section-add {
    margin-top: 50px;
  }

  .ourstory-intro-section-text-content {
    width: 100%;
    margin: 0 auto;
    min-height: auto;
    padding: 10px 0;
    margin-bottom: 10px;
    margin-top: 2px;
  }

  .ourstory-intro-section-text-content h1 {
    /* Reduced by 15% */
    text-align: left;
    position: relative;
    left: 18px;
    margin-bottom: 10px;
  }

  .ourstory-intro-section-text-content::after {
    display: none;
  }

  .ourstory-intro-section-text-content p {
    position: relative;
    left: 0;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;

  }

  .ourstory-intro-section-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
    box-sizing: border-box;
  }

  .ourstory-intro-section-image-container img {
    width: 100%;
    height: auto;
    max-height: 165px;
    margin-top: -10px;
    object-fit: cover;
    position: relative;
    border: none;
    box-sizing: border-box;    
  }


  .ourstory-section-3 {
    display: flex;
    flex-direction: column; /* Stack items vertically for mobile */
    align-items: flex-start;
    margin: 0; /* Remove the left margin for full width */
    padding: 0;
    width: 100%; /* Use the full width of the screen */
    height: auto;
    justify-content: flex-start; /* Align content to the top */
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .ourstory-section-3-text-content {
    position: relative;
    top: 0;
    left: 0; /* Reset left position */
    flex: none; /* Reset flex property for better stacking */
    margin: 0; /* Remove unnecessary margins */
    margin-left: 20px;
    margin-right: 20px;
  }

  .ourstory-section-3-text-content h2 {
    margin-bottom: 10px; /* Add space below the heading */
  }

  .ourstory-section-3-text-content p {
    line-height: 1.4; /* Adjust line spacing for better readability */
    color: #555555;
    margin-bottom: 10px; /* Reduce spacing between paragraphs */
  }

  .ourstory-section-3-text-content ul {
    list-style-type: none;
    margin: 0;
    padding: 0; /* Remove default padding */
  }

  .ourstory-section-3-text-content ul li {
    margin-bottom: 10px; /* Reduce space between list items */
    line-height: 1.5; /* Adjust line spacing */
    color: #555555;
    position: relative;
    padding-left: 15px; /* Adjust bullet spacing */
  }

  .ourstory-section-3-text-content ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: rgb(0, 0, 128);
    line-height: 1; /* Align bullet with text */
  }

}