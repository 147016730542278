@media (max-width: 1200px) {
    .ourservices-content-container {
        width: 1200px;
        max-width: 1200px;
        overflow-x: auto;
        box-sizing: border-box;
    }

    .trackshipment-content-container {
        width: 1200px;
        max-width: 1200px;
        overflow-x: auto;
        box-sizing: border-box;
    }

    .ourservices-section-2-types {
        display: flex;
        border: #E8E8E8 solid 1px;
        border-left: none;
        border-right: none;
        left: 1.5px;
        z-index: 2;
        width: 100%;
        margin-bottom: 80px;
    }

    body,
    html {
        overflow-x: auto;
    }
}

@media screen and (max-width: 768px) {

    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    div,
    li {
        color: #2c2c2c !important;
        font-family: 'Roboto', sans-serif !important;
    }

    p,
    li {
        line-height: 1.5rem !important;
    }


    p,
    a,
    li {
        font-size: 0.86rem !important;
        /* Reduced by 15% */
        letter-spacing: normal !important;
    }

    h1 {
        font-size: 1.5rem !important;
        /* Reduced by 15% */
    }

    h2 {
        font-size: 1.3rem !important;
        /* Reduced by 15% */
    }

    h3 {
        font-size: 1.2rem !important;
        /* Reduced by 15% */
    }

    h4 {
        font-size: 1.1rem !important;
        /* Reduced by 15% */
    }

    h5 {
        font-size: 1rem !important;
        /* Reduced by 15% */
        margin-bottom: -15px;
        margin-top: 20px;
    }

    h6 {
        font-size: 0.86rem !important;
        /* Reduced by 15% */
    }
}

.ourservices-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 100vh;
    box-sizing: border-box;
}

.default-image-container-add {
    right: -2px !important;
    box-sizing: border-box !important;
}


.ourservices-intro-section {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 165px;
    width: calc(100% - 165px);
    flex-grow: 1;
    position: relative;
    height: 400px;
    justify-content: space-between;
    z-index: 2;
    margin-top: 70px;
    margin-bottom: 80px;
}

.ourservices-intro-section-add {
    margin-top: 133px;
}

.ourservices-intro-section-text-content {
    position: relative;
    flex: 1;
    z-index: 2;
    min-height: 390px;
}

.ourservices-intro-section-text-content h1 {
    font-size: 2.5rem;
    transform: translateY(0px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    transition-delay: 0s;
    position: relative;
    left: 40px;
}

.ourservices-intro-section-text-content::after {
    content: '';
    display: block;
    width: calc(100% + 20px);
    max-height: 15px;
    min-height: 15px;
    position: relative;
    flex: 1;
    z-index: 99;
    left: 0px;
    background-color: rgb(0, 0, 128);
    margin-left: -40px;
    margin-top: 30px;
}

.ourservices-intro-section-text-content p {
    position: relative;
    left: -20px;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    background-color: white;
    color: #555555;
    transform: translateY(0px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    transition-delay: 0.125s;
}

.ourservices-intro-section-image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    bottom: 0px;
}

.ourservices-intro-section-image-container img {
    width: 95%;
    height: auto;
    height: 400px;
    object-fit: cover;
    position: relative;
    border: 2px solid #E8E8E8;
    border-right: none;
    border-bottom: rgb(0, 0, 108) solid 2px;
    box-sizing: border-box;
}


.grid-button {
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    transition: background-color 0.3s ease;
    height: 100px;
    border: 1px #E8E8E8 solid;
}

.grid-button:hover h4 {
    color: white !important;
}

.grid-button:hover {
    background-color: rgb(0, 0, 128, .7);
    color: #000;
}



.overlay-grid-types {
    position: absolute;
    top: 0;
    right: 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    width: 95%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.overlay-grid-types a {
    text-decoration: none !important;
}

.grid-button-types {
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    transition: background-color 0.3s ease;
    max-height: 78px;
    border: 1px #E8E8E8 solid;
}

.grid-button-types:hover h4 {
    color: white !important;
}

.grid-button-types:hover {
    background-color: rgb(0, 0, 128, .7);
    color: #000;
}






.overlay-grid-commodity {
    position: absolute;
    top: 0;
    right: 0px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    width: 95%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.overlay-grid-commodity a {
    text-decoration: none !important;
}

.overlay-grid-commodity h4 {
    font-size: 1.1rem !important;
}

.grid-button-commodity {
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 9px 1px;
    transition: background-color 0.3s ease;
    max-height: 46.8px;
    border: 1px #E8E8E8 solid;
}

.grid-button-commodity:hover h4 {
    color: white !important;

}

.grid-button-commodity:hover {
    background-color: rgb(0, 0, 128, .7);
    color: #000;
}






.overlay-grid-services {
    position: absolute;
    top: 0;
    right: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    width: 95%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.grid-button-services {
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    transition: background-color 0.3s ease;
    max-height: 78px;
    border: 1px #E8E8E8 solid;
}

.overlay-grid-services a {
    text-decoration: none !important;
}

.grid-button-services:hover h4 {
    color: white !important;
}

.grid-button-services:hover {
    background-color: rgb(0, 0, 128, .7);
    color: #000;
}


.ourservices-section-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 200px;
    width: calc(100% - 200px);
    flex-grow: 1;
    position: relative;
    height: auto;
    justify-content: space-between;
    z-index: 1;
}

.ourservices-section-2 p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555555;
}

.ourservices-section-2 h2 {
    font-size: 2rem;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.ourservices-section-2 ul {
    list-style-type: none;
    font-size: 1rem;
}

.ourservices-section-2 ul li {
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 1.7;
    color: #555555;
    position: relative;
    padding-left: 20px;
}

.ourservices-section-2 ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    font-size: 2rem;
    color: rgb(0, 0, 128);
    line-height: 0.9;
}

.ourservices-section-2-types {
    display: flex;
    border: #E8E8E8 solid 1px;
    border-left: none;
    border-right: none;
    min-height: 580px;
    left: 1.5px;
    z-index: 2;
    width: 100%;
    margin-bottom: 80px;
}

.ourservices-section-2-types:hover .ourservices-section-2-right h2 {
    color: rgb(0, 0, 128);
}

.ourservices-section-2-types:hover .ourservices-section-2-right p {
    opacity: 1;
}

.ourservices-section-2-left {
    flex: 1;
    display: flex;
    align-items: left;
    cursor: pointer;
    position: relative;
}

.ourservices-section-2-left-text {
    position: absolute;
    z-index: 1;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    opacity: 0;
    left: 30px;
    top: 10px;
    /* white-space: normal; */
    font-size: 1rem;
    width: 92%;
}

.ourservices-section-2-left-text h4 {
    line-height: 1px;
}

.ourservices-section-2-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ourservices-section-2-right p {
    margin: 0;
    opacity: 0;
}

.ourservices-section-2-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease-in-out;
    z-index: 2;
}

.ourservices-section-2-contact-us-link {
    opacity: 0;
    margin-top: 15px;
    padding: 10px 20px;
    height: 40px;
    background-color: #343a40;
    width: calc(50% - 40px);
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: opacity 1s ease-in-out 0.5s, transform 0s ease-in-out 0s, visibility 0s linear 0s;
    position: absolute;
    bottom: 0;
    z-index: 5;
    text-align: center;
    display: inline-block;
    line-height: 40px;
}

.ourservices-section-2-right a {
    font-size: 1.1rem !important;
}

.ourservices-section-2-contact-us-link:hover {
    background-color: rgb(0, 0, 108);
}

.ourservices-img-style-1 {
    object-position: 90%;
}

.ourservices-img-style-2 {
    object-position: 90%;
}

.ourservices-img-style-3 {
    object-position: 80%;
}

.ourservices-img-style-5 {
    object-position: 30%;
}

.ourservices-img-style-7 {
    object-position: 80%;
}


#toggle-transform-1,
#toggle-transform-2,
#toggle-transform-3,
#toggle-transform-4,
#toggle-transform-5,
#toggle-transform-6,
#toggle-transform-7,
#toggle-transform-8 {
    display: none;
}

#toggle-transform-1:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-1:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-1:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-1:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-1:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}

#toggle-transform-2:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-2:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-2:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-2:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-2:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}

#toggle-transform-3:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-3:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-3:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-3:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-3:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}

#toggle-transform-4:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-4:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-4:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-4:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-4:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}

#toggle-transform-5:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-5:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-5:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-5:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-5:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}

#toggle-transform-6:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-6:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-6:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-6:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-6:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}

#toggle-transform-7:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-7:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-7:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-7:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-7:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}

#toggle-transform-8:checked+.ourservices-section-2-left .ourservices-section-2-left-text {
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-8:checked+.ourservices-section-2-left img {
    transform: translateX(100%);
}

#toggle-transform-8:checked~.ourservices-section-2-right h2 {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#toggle-transform-8:checked~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
}

#toggle-transform-8:not(:checked)~.ourservices-section-2-right .ourservices-section-2-contact-us-link {
    visibility: hidden;
    opacity: 0;
}



@media screen and (max-width: 768px) {
    .ourservices-content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100vw;
        height: auto;
        box-sizing: border-box;
        overflow: hidden;
    }

    .ourservices-intro-section {
        flex-direction: column;
        display: flex;
        align-items: stretch;
        text-align: left;
        margin-left: 0;
        padding: 0;
        width: 100%;
        height: auto;
        margin-top: 60px;
        margin-bottom: 25px;
        box-sizing: border-box;
    }

    .ourservices-intro-section-add {
        margin-top: 50px;
    }

    .ourservices-intro-section-text-content {
        width: 100%;
        margin: 0 auto;
        min-height: auto;
        /* Allow flexibility */
        padding: 10px 0;
    }

    .ourservices-intro-section-text-content h1 {
        text-align: left;
        position: relative;
        left: 18px;
        margin-bottom: -10px;

    }

    .ourservices-intro-section-text-content::after {
        display: none;
    }

    .ourservices-intro-section-text-content p {
        position: relative;
        left: 0;
        margin-left: 20px;
        margin-right: 20px;
        text-align: left;
    }

    .ourservices-intro-section-image-container {
        display: flex;
        /* Enable flexbox for layout */
        flex-direction: column;
        /* Stack elements vertically */
        align-items: center;
        /* Center-align content */
        width: 100%;
        /* Full width of parent */
        height: auto;
        /* Dynamically adjust based on content */
        margin-top: 20px;
        /* Add spacing at the top */
        padding: 0;
        /* Reset padding */
        box-sizing: border-box;
        /* Include padding and borders */

    }

    .overlay-grid-types {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        width: 100vw;
        /* height: 287px; */
        /* background-color: #000; */
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        z-index: 2;
        position: relative;
        margin-bottom: 10px;
        /* top: -10px; */
    }

    .overlay-grid-types a {
        text-decoration: none !important;

    }

    .grid-button-types {
        background-color: rgba(255, 255, 255, 0.8);
        font-weight: bold;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        transition: background-color 0.3s ease;
        height: 50px;
        border: 1px white solid;
    }



    .grid-button-types:hover h4 {
        color: white !important;

    }


    .grid-button-types:hover {
        background-color: rgb(0, 0, 128, .7);
        color: #000;
    }


    .overlay-grid-services {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        width: 100vw;
        /* height: 2387px; */
        /* background-color: #000; */
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        z-index: 2;
        position: relative;
        top: 0px;
        margin-bottom: 10px;
    }

    .grid-button-services {
        background-color: rgba(255, 255, 255, 0.8);
        color: #333;
        font-weight: bold;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        transition: background-color 0.3s ease;
        height: 50px;
        border: 1px white solid;
    }

    .overlay-grid-services a {
        text-decoration: none !important;

    }

    .grid-button-services:hover h4 {
        color: white !important;

    }

    .grid-button-services:hover {
        background-color: rgb(0, 0, 128, .7);
        color: #000;
    }



    .default-image-container-add {
        right: 0px !important;
        margin: 0;
        /* box-sizing: border-box !important; */
    }

    .ourservices-section-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
        height: auto;
        box-sizing: border-box;
        margin: 0;
        z-index: 1;
        margin-bottom: 5px;
    }

    .ourservices-section-2-types {
        display: flex;
        border: #E8E8E8 solid 1px;
        border-left: none;
        border-right: none;
        left: 1.5px;
        z-index: 2;
        margin-bottom: 25px;
        min-height: 550px;
    }


    .ourservices-section-2-left {
        flex: 1;
        display: flex;
        align-items: left;
        cursor: pointer;
        position: relative;
        min-width: 100vw;
        min-height: auto;
    }

    .ourservices-section-2-right {
        flex: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 9999;
    }

    .ourservices-section-2-right h2 {
        position: absolute;
        left: 50%;
        /* Move to the center of the parent */
        transform: translateX(-50%);
        /* Adjust for the element's width */
        z-index: 9999;
        bottom: 0;
        text-align: center;
        width: 100vw;
        color: whitesmoke !important;
    }

    .ourservices-section-2-left-text {
        position: absolute;
        z-index: 1;
        transition: opacity 1s ease-in-out, transform 1s ease-in-out;
        opacity: 0;
        left: 0;
        top: 10px;
        width: 100%;
    }

    .ourservices-section-2-left-text h4 {
        line-height: 1px;
        position: relative;
        left: 19px;
    }

    .ourservices-section-2-left-text p {
        margin-left: 20px;
        margin-right: 20px;
    }

    .ourservices-section-2-left-text ul {
        margin-right: 20px;
    }

    .ourservices-section-2 ul li {
        margin-bottom: 15px;
        line-height: 1.7;
        color: #555555;
        position: relative;
        padding-left: 20px;
    }

    .ourservices-section-2-left img {
        width: 100%;
        height: 550px;
        object-fit: cover;
        transition: transform 1s ease-in-out;
        z-index: 2;
    }

    .ourservices-section-2-contact-us-link {
        opacity: 0;
        margin-top: 15px;
        height: 40px;
        background-color: #343a40;
        color: white;
        text-decoration: none;
        font-weight: bold;
        transition: opacity 1s ease-in-out 0.5s, transform 0s ease-in-out 0s, visibility 0s linear 0s;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        text-align: left;
        line-height: 40px;
        z-index: 9999;
    }

    .ourservice-section-2-mobile {
        width: 100vw;
        margin-top: -17px;
        margin-bottom: 5px;
        position: relative;
        background: none;
        /* Remove the background image from the section itself */
        z-index: 1;
        /* Ensure the content remains above the overlay */
    }

    .ourservice-section-2-mobile::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        opacity: 0.15;
        z-index: -1;
    }

    .ourservice-section-2-mobile p {
        margin-left: 20px;
        margin-right: 20px;
        color: black !important;
    }

    .ourservice-section-2-mobile h2 {
        margin-left: 20px;
        color: black !important;
    }

    .ourservice-section-2-mobile ul {
        margin-right: 20px;
        list-style-type: none;
        line-height: 1.5;
    }

    .ourservice-section-2-mobile a {
        text-decoration: none;
    }







    .ourservices-img-style-1 {
        object-position: 70%;
    }

    .ourservices-img-style-2 {
        object-position: 90%;
    }

    .ourservices-img-style-3 {
        object-position: 80%;
    }

    .ourservices-img-style-5 {
        object-position: 30%;
    }

    .ourservices-img-style-6 {
        object-position: 60%;
    }

    .ourservices-img-style-7 {
        object-position: 66%;
    }

    .ourservices-img-style-8 {
        object-position: 55%;
    }



    .item-types-image {
        position: relative !important;
        left: -20px !important;
        width: calc(100% + 40px) !important;
        /* width: 100vw !important; */
    }

    .add-a-little {
        margin-top: 10px !important;
    }






    .overlay-grid-commodity {
        position: relative;
        top: 0;
        right: 0px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(10, 1fr);
        width: 100vw;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        margin-bottom: 10px;
    }

    .overlay-grid-commodity a {
        text-decoration: none !important;
    }

    .overlay-grid-commodity h4 {
        font-size: 1    rem !important;
    }


    .grid-button-commodity {
        background-color: rgba(255, 255, 255, 0.8);
        color: #333;
        font-size: 1rem;
        font-weight: bold;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 9px 0px;
        transition: background-color 0.3s ease;
        max-height: 46.8px;
        /* min-width: 19%; */
        border: 1px white solid;
    }

    .grid-button-commodity:hover h4 {
        color: white !important;

    }

    .grid-button-commodity:hover {
        background-color: rgb(0, 0, 128, .7);
        color: #000;
    }









}