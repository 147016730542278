@media (max-width: 1200px) {
  .technology-content-container {
    width: 1200px;
    max-width: 1200px;
    overflow-x: auto;
    box-sizing: border-box;
  }

  .trackshipment-content-container {
    width: 1200px;
    max-width: 1200px;
    overflow-x: auto;
    box-sizing: border-box;
  }

  body,
  html {
    overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  li {
    color: #2c2c2c !important;
    font-family: 'Roboto', sans-serif !important;
  }

  p,
  li {
    line-height: 1.5rem !important;
  }


  p,
  a,
  li {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
    letter-spacing: normal !important;
  }

  h1 {
    font-size: 1.5rem !important;
    /* Reduced by 15% */
  }

  h2 {
    font-size: 1.3rem !important;
    /* Reduced by 15% */
  }

  h3 {
    font-size: 1.2rem !important;
    /* Reduced by 15% */
  }

  h4 {
    font-size: 1.1rem !important;
    /* Reduced by 15% */
  }

  h5 {
    font-size: 1rem !important;
    /* Reduced by 15% */
    margin-bottom: -15px;
    margin-top: 20px;
  }

  h6 {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
  }
}

.technology-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}

.technology-intro-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 200px;
  width: calc(100% - 200px);
  flex-grow: 1;
  position: relative;
  height: 400px;
  justify-content: space-between;
  z-index: 2;
  margin-top: 70px;
  margin-bottom: 80px;
}

.technology-intro-section-add {
  margin-top: 133px;
}

.technology-intro-section-text-content {
  position: relative;
  flex: 1;
  z-index: 2;
  min-height: 390px;
}

.technology-intro-section-text-content h1 {
  font-size: 2.5rem;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0s;
  position: relative;
  left: 40px;
}

.technology-intro-section-text-content::after {
  content: '';
  display: block;
  width: 97%;
  height: 15px;
  position: relative;
  flex: 1;
  z-index: 99;
  left: 0px;
  background-color: rgb(0, 0, 128);
  margin-top: 40px;
}

.technology-intro-section-text-content p {
  position: relative;
  left: -20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  color: #555555;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0.125s;
}

.technology-intro-section-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.technology-intro-section-image-container img {
  width: 95%;
  height: auto;
  height: 400px;
  object-fit: cover;
  position: relative;
  border: 2px solid #E8E8E8;
  border-right: none;
  border-bottom: rgb(0, 0, 108) solid 2px;
  box-sizing: border-box;
}


.technology-section-3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: auto;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  margin-top: -10px;
}

.technology-section-3-text-content {
  position: relative;
  top: -30px;
  left: 40px;
  flex: 1.2;
  margin-right: 100px;
}

.technology-section-3-text-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555555;
}

.technology-section-3-text-content h2 {
  font-size: 2rem;
}

.technology-section-3-text-content ul {
  list-style-type: none;
  font-size: 1rem;
}

.technology-section-3-text-content ul li {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.7;
  color: #555555;
  position: relative;
  /* Ensures the ::before works correctly */
  padding-left: 20px;
  /* Spacing for the bullet */
}

.technology-section-3-text-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 2rem;
  color: rgb(0, 0, 128);
  line-height: 0.9;
}




@media screen and (max-width: 768px) {
  .technology-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
  }

  .technology-intro-section {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    text-align: left;
    margin-left: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .technology-intro-section-add {
    margin-top: 50px;
  }

  .technology-intro-section-text-content {
    width: 100%;
    margin: 0 auto;
    min-height: auto;
    padding: 10px 0;
  }

  .technology-intro-section-text-content h1 {
    /* Reduced by 15% */
    text-align: left;
    position: relative;
    left: 18px;
    margin-bottom: -10px;

  }

  .technology-intro-section-text-content::after {
    display: none;
  }

  .technology-intro-section-text-content p {
    position: relative;
    left: 0;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;

  }

  .technology-intro-section-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
    box-sizing: border-box;

  }

  .technology-intro-section-image-container img {
    width: 100%;
    height: auto;
    max-height: 250px;
    /* margin-top: -10px; */
    object-fit: cover;
    position: relative;
    border: none;
    box-sizing: border-box;
  }

  .technology-section-3 {
    display: flex;
    flex-direction: column;
    /* Stack elements vertically */
    align-items: flex-start;
    margin: 0;
    width: 100%;
    flex-grow: 1;
    position: relative;
    height: auto;
    margin-bottom: 5px;
    justify-content: flex-start;
    /* Align items at the top */
    margin-top: 0;
    /* Remove negative margin */
  }

  .technology-section-3-text-content {
    position: relative;
    top: 0;
    left: 0;
    flex: 1;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;
  }

  .technology-section-3-text-content p {

    line-height: 1.4;
    /* Adjust line height for readability */
    color: #555555;
  }

  .technology-section-3-text-content a {
    /* Reduced by 15% */
    line-height: 1.4;
    /* Adjust line height for readability */
  }

  .technology-section-3-text-content h2 {

    margin-bottom: 10px;
    /* Add spacing below the heading */
  }

  .technology-section-3-text-content ul {
    list-style-type: none;
    padding-left: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove margin */
  }

  .technology-section-3-text-content ul li {
    margin-bottom: 10px;
    /* Reduced by 15% */
    line-height: 1.6rem !important;
    /* Adjust line height */
    color: #555555;
    position: relative;
    /* Ensure bullet positioning works */
    /* padding-left: 15px; */
    /* Add space for custom bullet */
  }

  .technology-section-3-text-content ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    /* Adjust bullet size */
    color: rgb(0, 0, 128);
    /* Use defined color for bullet */
    /* line-height: 1.5; */
    /* Adjust bullet alignment */
  }
  
}






.trackshipment-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}

.trackshipment-intro-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 240px;
  width: calc(100% - 200px);
  flex-grow: 1;
  position: relative;
  height: 100px;
  justify-content: space-between;
  z-index: 2;
  margin-top: 70px;
  margin-bottom: 80px;
}

#transvirtual-track {
  position: relative;
  top: -55px;
  margin-bottom: 60px;
}

.transvirtual-Search {
  gap: 10px;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  max-width: 450px;
  margin: 10px 0;
}

.transvirtual-Search .lbl {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  min-width: 150px !important;
  position: relative;
  top: 17px;
  margin: 0;
}

.transvirtual-Search .value input[type="text"] {
  width: 250px;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s;
}

.transvirtual-Search .value input[type="text"]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.transvirtual-Search .transvirtual-button input[type="button"] {
  background-color: #003f7d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.transvirtual-Search .transvirtual-button input[type="button"]:hover {
  background-color: #002a5c;
}

.transvirtual-Search .transvirtual-button input[type="button"]:active {
  background-color: #003f7f;
}

#transvirtual-track>div.transvirtual-error {
  color: #cc0000 !important;
  border-radius: 4px;
  /* Rounded corners */
  padding: 0px;
  /* Padding inside the error box */
  font-size: 14px;
  /* Adjust the font size */
  position: absolute;
  margin-top: -25px;
  /* Space above the error box */
}

.transvirtual-consignmentSection {
  text-align: left;
  position: relative;
  left: -15px;
}

.transvirtual-consignmentSection .transvirtual-title {
  font-size: 1.2rem;
  /* Slightly larger font for the title */
  font-weight: bold;
  /* Emphasize the title */
  margin-bottom: 15px;
  /* Space between the title and the first row */
  color: #333;
  /* Dark grey for better readability */
}

.transvirtual-consignmentSection .row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  position: relative;
  left: 15px;
}

.transvirtual-consignmentSection .row .lbl {
  font-weight: bold;
  /* Highlight the label */
  width: 180px !important;
  color: #555;
  /* Darker grey for labels */
  position: relative;
  top: 10px;
}

.transvirtual-consignmentSection .row .value {
  flex: 1;
  /* Allow value to take the remaining space */
  color: #000;
  /* Black for value text */
  font-weight: normal;
  /* Keep the value text lighter for contrast */
  overflow-wrap: break-word;
  /* Ensure long text wraps properly */
}

.transvirtual-consignmentSection .row .value small {
  color: #777;
  /* Lighter grey for less important text */
  font-size: 12px;
  /* Smaller font size for small text */
}

.transvirtual-consignmentSection .transvirtual-Status,
.transvirtual-consignmentSection .transvirtual-ReceivedBy,
.transvirtual-consignmentSection .transvirtual-ConsignmentNumber,
.transvirtual-consignmentSection .transvirtual-ReceiverAddress,
.transvirtual-consignmentSection .transvirtual-SpecialInstructions,
.transvirtual-consignmentSection .transvirtual-TotalQty,
.transvirtual-consignmentSection .transvirtual-TotalWeight,
.transvirtual-consignmentSection .transvirtual-EstDelDate {
  margin-top: 5px;
  /* Space between specific consignment details */
}

.transvirtual-mapSection {
  align-items: left;
  justify-content: center;
  margin-bottom: 15px;
  margin-left: -15px;
}

.transvirtual-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  text-align: left;
  position: relative;
}

.transvirtual-map {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #e5e3df;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-top: 20px;
}





.transvirtual-scanSection {
  position: relative;
  left: -15px;
}

.transvirtual-map iframe,
.transvirtual-map>div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;

}

table.GridScansAdv {
  width: 97.7%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  margin-left: 14px;
  margin-top: 10px;
}

table.GridScansAdv thead th {
  background-color: rgb(0, 0, 128);
  color: #fff;
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  text-transform: uppercase;
}

table.GridScansAdv tbody tr {
  background-color: #fff;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

table.GridScansAdv tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

table.GridScansAdv tbody tr:hover {
  background-color: #E8E8E8;
}

table.GridScansAdv td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.transvirtual-plink {
  position: relative;
  font-size: .9rem !important;
  /* top: -10px; */
}

.transvirtual-plink a {
  font-size: .9rem !important;
}



.trackshipment-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}


.trackshipment-intro-section {
  display: flex;
  margin-left: 165px;
  width: calc(100% - 165px);
  flex-grow: 1;
  position: relative;
  max-height: 100px;
  justify-content: space-between;
  z-index: 2;
  padding: 0;
  margin-top: 190px;
}

.trackshipment-intro-section-text-content {
  position: relative;
  flex: 1;
  z-index: 2;
  max-width: 65%;
}

.trackshipment-intro-section-text-content h1 {
  font-size: 2.5rem;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0s;
  position: relative;
  margin-bottom: 20px;
}

.trackshipment-intro-section-text-content p {
  position: relative;
  left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  color: #555555;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0.125s;
}

.trackshipment-section-3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: auto;
  justify-content: space-between;
  margin-bottom: 30px;
  /* margin-top: -10px; */
  /* background-color: #000; */
}

.trackshipment-section-3-text-content {
  position: relative;
  left: 40px;
  flex: 1.2;
  margin-right: 100px;
}

.trackshipment-section-3-text-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555555;
}






.trackshipment-dropdown {
  width: 100%;
  max-width: 200px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background-color: #fff;
  border: 1.5px solid #003f7d;
  
  cursor: pointer;
  
  outline: none;
  transition: all 0.3s ease-in-out;
  margin-left: 0px;
}

/* When hovering */
.trackshipment-dropdown:hover {
  border-color: #002a5c;
}

/* When focused (clicked) */
.trackshipment-dropdown:focus {
  border-color: #003f7d;
  box-shadow: 0 0 5px rgba(0, 63, 125, 0.5);
}

/* Dropdown Arrow Customization - Flipped Up */
.trackshipment-dropdown {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23003f7d' d='M2 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right -23px;
  background-size: 30px;
}

/* Options inside the dropdown */
.trackshipment-dropdown option {
  font-size: 14px;
  color: #333;
}

/* Disabled option (default placeholder) */
.trackshipment-dropdown option[value=""] {
  color: #aaa;
  font-style: italic;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .trackshipment-dropdown {
    max-width: 100%;
    font-size: 14px;
    padding: 8px;
  }
}





/* ====== MOBILE/RESPONSIVE OVERRIDES ====== */

@media (max-width: 768px) {
  .trackshipment-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
  }

  .trackshipment-intro-section {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0;
    margin-top: 63px;
  }

  .trackshipment-section-3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0;
    width: 100%;
    flex-grow: 1;
    position: relative;
    height: auto;
    justify-content: space-between;
  }

  .trackshipment-section-3-text-content {
    position: relative;
    left: 40px;
    flex: 1.2;
    margin-right: 100px;
  }

  .trackshipment-intro-section-text-content {
    position: relative;
    flex: 1;
    z-index: 2;
  }

  .trackshipment-intro-section-text-content h1 {
    transform: translateY(0px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    transition-delay: 0s;
    position: relative;
    left: 20px;
  }

  .trackshipment-intro-section-text-content p {
    position: relative;
    left: 20px;
    padding: 0;
    margin-top: -10px;
    background-color: white;
    width: 89vw;
    color: #555555;
    transform: translateY(0px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    transition-delay: 0.125s;
  }

  #transvirtual-track {
    position: relative;
    top: 52px;
    left: -40px;
    margin-bottom: 60px;
    margin: 0;
    height: 240px;
    /* background-color: #000; */
    width: 100vw !important;
  }

  .transvirtual-Search {
    gap: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    max-width: none;
    min-width: none;
    padding: 20px;
    margin: 20px;
  }

  .transvirtual-Search .lbl {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    min-width: 150px !important;
    position: relative;
    top: 0px;
    margin: 0;
  }

  .transvirtual-Search .value input[type="text"] {
    width: 99%;
    padding: 8px 10px;
    position: relative;
    left: -10px;
    top: 15px;
    border: 1px solid #ccc;
    outline: none;
    transition: border-color 0.3s;
  }


  .transvirtual-Search .value input[type="text"]:focus {
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .transvirtual-Search .transvirtual-button input[type="button"] {
    background-color: #003f7d;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    left: -150px;
    top: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .transvirtual-Search .transvirtual-button input[type="button"]:hover {
    background-color: #002a5c;
  }

  .transvirtual-Search .transvirtual-button input[type="button"]:active {
    background-color: #003f7f;
  }

  #transvirtual-track>div.transvirtual-error {
    color: #cc0000 !important;
    border-radius: 4px;
    /* Rounded corners */
    padding: 0px;
    /* Padding inside the error box */
    font-size: 14px;
    /* Adjust the font size */
    position: absolute;
    margin-top: 165px;
    left: 25px;
    /* Space above the error box */
  }

  .transvirtual-consignmentSection {
    text-align: left;
    position: relative;
    top: -20px;
    left: 7px;
    width: 96% !important;
  }

  .transvirtual-consignmentSection .transvirtual-title {
    font-size: 1.2rem;
    /* Slightly larger font for the title */
    font-weight: bold;
    /* Emphasize the title */
    margin-bottom: 15px;
    /* Space between the title and the first row */
    color: #333;
    /* Dark grey for better readability */
  }

  .transvirtual-consignmentSection .row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    position: relative;
    left: 15px;
    width: 95% !important;
  }

  .transvirtual-consignmentSection .row .lbl {
    font-weight: bold;
    /* Highlight the label */
    width: 180px !important;
    color: #555;
    /* Darker grey for labels */
    position: relative;
    top: 10px;
  }

  .transvirtual-consignmentSection .row .value {
    flex: 1;
    /* Allow value to take the remaining space */
    color: #000;
    /* Black for value text */
    font-weight: normal;
    /* Keep the value text lighter for contrast */
    overflow-wrap: break-word;
    /* Ensure long text wraps properly */
  }

  .transvirtual-consignmentSection .row .value small {
    color: #777;
    /* Lighter grey for less important text */
    font-size: 12px;
    /* Smaller font size for small text */
  }

  .transvirtual-consignmentSection .transvirtual-Status,
  .transvirtual-consignmentSection .transvirtual-ReceivedBy,
  .transvirtual-consignmentSection .transvirtual-ConsignmentNumber,
  .transvirtual-consignmentSection .transvirtual-ReceiverAddress,
  .transvirtual-consignmentSection .transvirtual-SpecialInstructions,
  .transvirtual-consignmentSection .transvirtual-TotalQty,
  .transvirtual-consignmentSection .transvirtual-TotalWeight,
  .transvirtual-consignmentSection .transvirtual-EstDelDate {
    margin-top: 5px;
    /* Space between specific consignment details */
  }

  .transvirtual-mapSection {
    align-items: left;
    justify-content: center;
    margin: 0;
    text-align: left;
    position: relative;
    top: -20px;
    left: 7px;
    width: 96% !important;
  }

  .transvirtual-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    text-align: left;
    position: relative;
  }

  .transvirtual-map {
    width: 92% !important;
    height: 400px;
    position: relative;
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #e5e3df;
    margin-bottom: 50px;
    margin-left: 15px;
    margin-top: 20px;
  }


  .transvirtual-scanSection {
    position: relative;
    left: -15px;
    text-align: left;
    position: relative;
    top: -20px;
    left: 7px;
    width: 96% !important;
  }

  .transvirtual-map iframe,
  .transvirtual-map>div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;

  }

  table.GridScansAdv {
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    margin-left: 14px;
    margin-top: 10px;

    text-align: left;
    position: relative;
    /* top: -20px; */
    left: 0px;
    max-width: 90% !important;
  }

  table.GridScansAdv th:nth-child(n+5),
  table.GridScansAdv td:nth-child(n+5) {
    display: none;
  }

  table.GridScansAdv thead th {
    background-color: rgb(0, 0, 128);
    color: #fff;
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
    text-transform: uppercase;
  }

  table.GridScansAdv tbody tr {
    background-color: #fff;
    border: 1px solid #ddd;
    transition: background-color 0.3s ease;
  }

  table.GridScansAdv tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  table.GridScansAdv tbody tr:hover {
    background-color: #E8E8E8;
  }

  table.GridScansAdv td {
    padding: 7.5px;
    border: 1px solid #ddd;
    text-align: left;
  }

  .transvirtual-plink {
    position: relative;
    font-size: .9rem !important;
    left: 20px;
    margin-bottom: 20px;
    /* top: -10px; */
  }

  .transvirtual-plink a {
    font-size: .9rem !important;
  }

  .trackshipment-dropdown {
    width: 100%; 
    max-width: 200px; 
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    background-color: #fff;
    border: 1.5px solid #003f7d; /* QFM Theme Color */
    cursor: pointer;
    /* appearance: none;  */
    outline: none;
    transition: all 0.3s ease-in-out;
    margin-left: 20px;
  }

}