@media (max-width: 1280px) {
  .services-content-container {
    width: 1280px;
    max-width: 1280px;
    overflow-x: auto;
    box-sizing: border-box;
  }

  body,
  html {
    overflow-x: auto;
  }
}


@media screen and (max-width: 768px) {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  li {
    color: #2c2c2c !important;
    font-family: 'Roboto', sans-serif !important;
  }

  p, li {
      line-height: 1.5rem !important;
    }
    

  p,
  a,
  li {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
    letter-spacing: normal !important;
  }

  h1 {
    font-size: 1.5rem !important;
    /* Reduced by 15% */
  }

  h2 {
    font-size: 1.3rem !important;
    /* Reduced by 15% */
  }

  h3 {
    font-size: 1.2rem !important;
    /* Reduced by 15% */
  }

  h4 {
    font-size: 1.1rem !important;
    /* Reduced by 15% */
  }

  h5 {
    font-size: 1rem !important;
    /* Reduced by 15% */
    margin-bottom: -15px;
    margin-top: 20px;
  }

  h6 {
    font-size: 0.86rem !important;
    /* Reduced by 15% */
  }
  
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
li {
  color: #2c2c2c !important;
  font-family: 'Roboto', sans-serif !important;
}

p,
a,
li {
  font-size: .95rem !important;
  letter-spacing: normal !important;
}

p {
  line-height: 1.5rem !important;
}

h1 {
  font-size: 2.35rem !important;
}

h2 {
  font-size: 1.9rem !important;
}

h3 {
  font-size: 1.6625rem !important;
}

h4 {
  font-size: 1.425rem !important;
}

h5 {
  font-size: 1.1875rem !important;
  margin-bottom: -15px;
  margin-top: 20px;
}

h6 {
  font-size: .95rem !important;
}

.default-intro-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 165px;
  width: calc(100% - 165px);
  flex-grow: 1;
  position: relative;
  height: 400px;
  justify-content: space-between;
  z-index: 2;
  margin-top: 70px;
  margin-bottom: 80px;
}

.default-intro-section-add {
  margin-top: 133px;
}

.default-intro-section-text-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  position: relative;
  flex: 1;
  z-index: 2;
  /* min-height: 390px; */
  /* background-color: #0A66C2; */
}

.default-intro-section-text-content h1 {
  font-size: 2.5rem;
  transform: translateY(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0s;
  position: relative;
  margin-bottom: 10px;
  /* left: 40px; */
}

.default-intro-section-text-content::after {
  content: '';
  display: block;
  width: calc(100% + 20px);
  max-height: 15px;
  min-height: 15px;
  position: relative;
  flex: 1;
  z-index: 99;
  left: 0px;
  background-color: rgb(0, 0, 128);
  margin-left: -40px;
  margin-top: 30px;
}

.default-intro-section-text-content p {
  position: relative;
  color: #555555;
  transform: translateY(0px);
  margin-right: 20px;
  transition: opacity 1s ease-out, transform 1s ease-out;
  transition-delay: 0.125s;
  margin-bottom: 10px;
}

.default-intro-section-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.default-intro-section-image-container img {
  width: 95%;
  height: auto;
  height: 400px;
  object-fit: cover;
  position: relative;
  border: 2px solid #E8E8E8;
  border-right: none;
  border-bottom: rgb(0, 0, 108) solid 2px;
  box-sizing: border-box;
}

.default-quote {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
  margin-left: 125px;
  width: calc(100% - 125px);
  background-color: whitesmoke;
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 128);
  border-left: none;
  border-right: none;
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05);
  height: auto;
  margin-bottom: 80px;
}

.default-quote a {
  text-decoration: none;
}

.default-quote-text-content {
  position: relative;
  left: 20px;
  margin-right: 30px;
  flex: 1.5;
}

.default-quote-text-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555555;
  padding-top: 5px;

}

.default-quote-quote {
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #343a40;
  border-left: 2px solid rgb(0, 0, 128);
  box-sizing: border-box;
  cursor: pointer;
  min-height: auto;
  padding: 0px 50px;
}

.default-quote-quote a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  height: 100%;
  background-color: inherit;
}

.default-quote h4 {
  font-size: 1.75rem;
  color: white !important;
}

.default-quote-quote:hover {
  background-color: rgb(0, 0, 108);
}

.australia-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 200px);
  flex-grow: 1;
  position: relative;
  height: auto;
  justify-content: space-between;
  margin-bottom: 45px;
  /* background-color: #0A66C2; */
}

.australia-section-text-content {
  position: relative;
  top: -10px;
  left: 15px;
  flex: 1;
  margin-right: 30px;
  height: auto;
}



.australia-section-image-container {
  flex: .9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 0px;
  left: 0px;
  margin-bottom: -45px;
}

.australia-section-image-container img {
  object-fit: scale-down;
  width: 100%;
  height: 500px;
  position: relative;
  top: -50px;
}

.australia-section-text-content h2 {
  font-size: 2rem;
}

.australia-section-text-content ul {
  list-style-type: none;
  font-size: 1rem;
}

.australia-section-text-content ul li {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.7;
  color: #555555;
}

.australia-section-text-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 2rem;
  color: rgb(0, 0, 128);
  line-height: .9;
}

.australia-section-text-content a {
  text-decoration: none;
}

.australia-section-text-content a:hover {
  color: rgb(0, 0, 128);
  text-decoration: underline;
}

.default-section-content-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  justify-content: space-between;
  margin-bottom: 70px;
  border-bottom: 1px solid #E8E8E8;
  box-sizing: border-box;
}

.default-section-content-left-text-content {
  position: relative;
  left: 40px;
  flex: 1.2;
  margin-right: 50px;
  /* margin-bottom: auto; */
  margin-bottom: 20px;
}

.default-section-content-left-text-content h2 {
  font-size: 2rem;
}

.default-section-content-left-text-content ul {
  list-style-type: none;
  font-size: 1rem;
}

.default-section-content-left-text-content ul li {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.7;
  color: #555555;
}

.default-section-content-left-text-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 2rem;
  color: rgb(0, 0, 128);
  line-height: .9;
}


.default-section-content-left-image-container {
  flex: 1.1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 0px;
  margin-top: auto;
}

.default-section-content-left-image-container img {
  width: 95%;
  height: auto;
  height: 400px;
  object-fit: cover;
  border: 2px solid #E8E8E8;
  border-right: none;
  box-sizing: border-box;
}

.faq-text-content {
  position: relative;
  left: 40px;
  flex: 1.2;
  margin-right: 90px;
  margin-top: -30px;
}

.faq-text-content h2 {
  font-size: 2rem;
}

.faq-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  cursor: pointer;
  overflow: hidden;
}

.faq-content {
  transition: max-height 1.5s ease, opacity 0.5s ease-in;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding-top: 0;
}

.faq-item.active .faq-content {
  max-height: 200px;
  opacity: 1;
  /* padding-top: 10px; */
}

.faq-content:not(.visible) {
  transition: max-height 0.4s ease-out, opacity 0.2s ease-out;
}

.faq-item h5 {
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 5px 0;
}

.faq-item h5:hover {
  color: rgb(0, 0, 128) !important;
}

.faq-item:hover {
  border-bottom: 1px solid rgb(0, 0, 128);
}

.faq-item.active h5 {
  color: rgb(0, 0, 128) !important;
  font-weight: bold;
}

.faq-item.active {
  border-bottom: 1.5px solid rgb(0, 0, 128);
}

.faq-content p {
  margin: 0;
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  padding-top: 10px;
  padding-bottom: 5px;
}

.faq-content ul {
  list-style-type: none;
  font-size: 1rem;
}

.faq-content ul li {
  font-size: 1rem;
  line-height: 1.7;
  color: #555555;
}

.faq-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 2rem !important;
  color: rgb(0, 0, 128);
  line-height: .8;
}

.melbourne-faq-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 200px;
  width: calc(100% - 200px);
  flex-grow: 1;
  position: relative;
  height: 690px;
  justify-content: space-between;
}

.sydney-faq-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 200px;
  width: calc(100% - 200px);
  flex-grow: 1;
  position: relative;
  height: 600px;
  justify-content: space-between;
}

.brisbane-faq-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 200px;
  width: calc(100% - 200px);
  flex-grow: 1;
  position: relative;
  height: 590px;
  justify-content: space-between;
}

.perth-faq-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 200px;
  width: calc(100% - 200px);
  flex-grow: 1;
  position: relative;
  height: 600px;
  justify-content: space-between;
}




.adelaide-faq-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 530px;
  justify-content: space-between;
}

.semi-faq-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 540px;
  justify-content: space-between;
}

.pallet-faq-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 590px;
  justify-content: space-between;
}

.crate-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 625px;
  justify-content: space-between;
}

.satchel-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 650px;
  justify-content: space-between;
}

.envelope-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 585px;
  justify-content: space-between;
}


.integration-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  /* height: 1000px; */
  margin-bottom: 150px;
  /* height: auto; */
  justify-content: space-between;
}

.drum-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 650px;
  justify-content: space-between;
}

.ibc-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 585px;
  justify-content: space-between;
}

.panel-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 585px;
  justify-content: space-between;
}

.stillage-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 670px;
  justify-content: space-between;
}

.bdouble-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 585px;
  justify-content: space-between;
}

.container-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 580px;
  justify-content: space-between;
}

.carton-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 580px;
  justify-content: space-between;
}

.road-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 650px;
  justify-content: space-between;
}

.rail-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 590px;
  justify-content: space-between;
}

.air-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 630px;
  justify-content: space-between;
}

.local-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 720px;
  justify-content: space-between;
}

.project-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 580px;
  justify-content: space-between;
}

.dg-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 560px;
  justify-content: space-between;
}

.general-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 510px;
  justify-content: space-between;
}

.express-faq-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 125px;
  width: calc(100% - 125px);
  flex-grow: 1;
  position: relative;
  height: 555px;
  justify-content: space-between;
}


.services-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}

.development-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  /* Add some padding to avoid the containers touching the edges */
  border-radius: 8px;
  /* Optional: Add rounded corners to the background */
}

.development-message {
  background-color: #ffffff;
  border: 2px solid rgb(0, 0, 128);
  padding: 20px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  /* Add space between the two containers */
}

.development-message h1 {
  color: rgb(0, 0, 128);
  font-size: 24px;
  margin-bottom: 10px;
}

.development-message p {
  color: #2c2c2c;
  font-size: 18px;
  margin-top: 0;
}

.development-message small {
  display: block;
  margin-top: 15px;
  color: #666666;
}

.customer-portal-link {
  text-decoration: none;
  /* Remove underline from link */
  width: 100%;
  /* Full width of the container */
  display: flex;
  justify-content: center;
  /* Center the content horizontally */
}

.customer-portal-message {
  background-color: #ffffff;
  border: 2px solid rgb(0, 0, 128);
  padding: 20px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  /* Optional: Restrict the width */
  background-color: #F0F0F0;
}

.customer-portal-message h2 {
  color: rgb(0, 0, 128);
  font-size: 24px;
  margin: 0;
}

.customer-portal-message:hover {
  background-color: #e0e0e0;
  /* Optional: Add hover effect */
}

ul {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 0;
}

ul li {
  margin-bottom: 10px;
  font-size: 1.1rem;
  line-height: 1.5em;
}

ul li::marker {
  color: rgb(0, 0, 128);
}

.scroll-up {
  position: fixed;
  bottom: 65px;
  left: 12px;
  width: 100px;
  height: 30px;
  color: black;
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgb(0, 0, 128);
  cursor: pointer;
  opacity: 0.8;
}

.scroll-up:hover {
  border: 1px solid rgb(0, 0, 128);
}

.scroll-up-mobile {
  position: fixed;
  bottom: 50px;
  right: 21px;
  width: 35px;
  height: 22px;
  color: rgb(0, 0, 108) !important;  
  display: flex;
  font-size: 2.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 15;
  padding-top: 2.6px;
  -webkit-text-stroke: 1px whitesmoke;
  -webkit-tap-highlight-color: transparent;
}

.scroll-up-mobile::selection {
  background: transparent;
  /* Remove default background highlight */
  color: #0A66C2;
}

.scroll-up-mobile:hover {
  color: #0A66C2;
}

.appearing-effect {
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.appearing-effect.visible {
  opacity: 1;
  transform: translateY(0);
}

.vertical-line {
  width: 1.5px;
  background-color: rgb(0, 0, 128);
  height: 100%;
  position: absolute;
  left: 125px;
  top: 0;
  z-index: 2;
}

.App-footer {
  background-color: #282c34;
  padding: 10px;
  height: 30px;
  color: white;
  text-align: center;
  position: relative;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-footer p {
  color: whitesmoke !important;
  margin: 0;
  font-size: .75rem !important;
}


.manage-height{
  height: auto;
position: relative;
width: 85%;
left: 170px !important;
/* background-color: #0A66C2; */
}
.manage-height h5{
  margin-bottom: 10px;
  margin-top: 30px;
}





