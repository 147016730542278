@media (max-width: 1200px) {
  .App-header {
    width: auto;
  }


  .App-header.scrolled {
    width: 1200px;
    min-width: 1200px;
    max-width: 1200px;
    overflow-x: none;
    box-sizing: border-box;
  }


  }

  .App-header {
    display: flex;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 15%;
    z-index: 100;
  }

  .App-header-reposition {
    display: flex;
    width: 1114px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: #004182; */
  }

  .header-section-1 {
    display: flex;
    align-items: left;
    justify-content: flex-start;
    width: auto;
  }

  .header-section-1 img {
    height: 125px;
    width: auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 1;
  }

  .header-section-2 {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    /* background-color: #F0F0F0; */
  }

  .header-section-2 h2 {
    font-weight: 900 !important;
    color: rgb(0, 0, 85) !important;
    -webkit-text-stroke: 1.2px rgb(0, 0, 95);
    letter-spacing: 0.07rem;
    margin: 0;
    margin-left: 13px;
    margin-bottom: 13px;

  }

  .header-section-2 nav {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }

  .header-nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }

  .header-nav-links li {
    margin: 0;
    padding: 0;
    margin-left: 13px;
    margin-bottom: 8px;
  }

  /* .header-nav-links li:last-child {
  position: absolute;
  right: 0px; 
  top: 52px; 
} */

  /* .header-nav-links li:last-child a{
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgb(0, 0, 128);
} */

  .header-nav-links li a {
    position: relative;
    padding: 10px 10px;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
    font-size: 1.1rem;

    overflow: hidden;
    cursor: pointer;
    transition: color 0.1s ease-in-out;
  }

  .header-nav-links li a .border-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: white;
    transform: translateX(-50%);
    transition: width 0.15s ease-in-out;
  }

  .header-nav-links li a .border-left,
  .header-nav-links li a .border-right {
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 0;
    background-color: white;
    transition: height 0.15s ease-in-out 0.15s;
  }

  .header-nav-links li a .border-left {
    left: 0;
    transform-origin: bottom;
  }

  .header-nav-links li a .border-right {
    right: 0;
    transform-origin: bottom;
  }


  .header-nav-links li a .border-top-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: white;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.15s ease-in-out 0.3s;
  }

  .header-nav-links li a .border-top-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 2px;
    background-color: white;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.15s ease-in-out 0.3s;
  }

  .header-nav-links li a .background-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transform: scale(0);
    transform-origin: center;
    transition: transform 0.15s ease-in-out 0.45s;
    z-index: -1;
  }

  .header-nav-links li a:hover .border-bottom {
    width: 100%;
  }

  .header-nav-links li a:hover .border-left,
  .header-nav-links li a:hover .border-right {
    height: 100%;
  }

  .header-nav-links li a:hover .border-top-left {
    transform: scaleX(1);
  }

  .header-nav-links li a:hover .border-top-right {
    transform: scaleX(1);
  }

  .header-nav-links li a:hover {
    color: rgb(0, 0, 128);
    font-weight: 400;
    transition: color 0.3s ease-in-out 0.5s;
  }

  .header-nav-links li a:hover .background-fill {
    transform: scale(1);
  }

  .header-nav-links li a:not(:hover) .border-top-left,
  .header-nav-links li a:not(:hover) .border-top-right {
    transform: scaleX(0);
    transition: transform 0.15s ease-in-out 0s;
  }

  .header-nav-links li a:not(:hover) .border-left,
  .header-nav-links li a:not(:hover) .border-right {
    height: 0;
    transition: height 0.15s ease-in-out 0.15s;
  }

  .header-nav-links li a:not(:hover) .border-bottom {
    width: 0;
    transition: width 0.15s ease-in-out 0.3s;
  }

  .header-nav-links li a:not(:hover) .background-fill {
    transform: scale(0);
    transition: transform 0.15s ease-in-out;
  }

  .dropdown {
    position: relative;
  }

  .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    top: 28px;
    left: -13px;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  

    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, auto);
    /* 6 rows per column => 6 items per column */
    grid-auto-columns: auto;
    /* Width of each column; adjust as needed */
    grid-row-gap: -1 5px !important;
    grid-column-gap: 0px;
    /* If there's more content than fits in the columns, allow scrolling */
    max-height: auto;
    /* Adjust to your preference */
    transition-delay: 0s !important;
  }

  .dropdown-services {
    grid-template-rows: repeat(4, auto);
    width: 270px;
  }


  .dropdown:hover .dropdown-menu,
  .dropdown:focus-within .dropdown-menu {
    opacity: 1;
    pointer-events: auto; 
    visibility: visible;
  }

  .dropdown-menu li {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    margin-bottom: 0px;
    transition-delay: 0s;
  }

  /* When hovering, apply staggered transition delays starting at 0.03s and adding 0.03s per item */
  .dropdown:hover .dropdown-menu li:nth-child(1) {
    transition-delay: 0.03s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(2) {
    transition-delay: 0.06s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(3) {
    transition-delay: 0.09s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(4) {
    transition-delay: 0.12s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(5) {
    transition-delay: 0.15s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(6) {
    transition-delay: 0.18s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(7) {
    transition-delay: 0.21s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(8) {
    transition-delay: 0.24s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(9) {
    transition-delay: 0.27s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(10) {
    transition-delay: 0.30s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(11) {
    transition-delay: 0.33s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(12) {
    transition-delay: 0.36s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(13) {
    transition-delay: 0.39s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(14) {
    transition-delay: 0.42s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(15) {
    transition-delay: 0.45s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(16) {
    transition-delay: 0.48s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(17) {
    transition-delay: 0.51s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(18) {
    transition-delay: 0.54s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(19) {
    transition-delay: 0.57s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(20) {
    transition-delay: 0.60s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(21) {
    transition-delay: 0.63s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(22) {
    transition-delay: 0.66s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(23) {
    transition-delay: 0.69s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(24) {
    transition-delay: 0.72s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(25) {
    transition-delay: 0.75s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(26) {
    transition-delay: 0.78s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(27) {
    transition-delay: 0.81s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(28) {
    transition-delay: 0.84s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(29) {
    transition-delay: 0.87s;
  }

  .dropdown:hover .dropdown-menu li:nth-child(30) {
    transition-delay: 0.90s;
  }

  /* When hovering, show and animate list items */
  .dropdown:hover .dropdown-menu li,
  .dropdown:focus-within .dropdown-menu li {
    opacity: 1;
    transform: translateY(0);
  }

  .dropdown-menu li a:hover {
    background-color: rgb(0, 0, 108);
    color: #ffffff;
  }

  .dropdown-menu li a {
    margin-top: 12px;
    text-decoration: none;
    color: white !important;
    font-weight: 400 !important;
    display: block;
    transition: background-color 0.3s ease;
    padding: 8px 10px;
  }

  .dropdown:hover>a,
  .dropdown:focus-within>a {
    color: rgb(0, 0, 128) !important;
  }

  .dropdown:hover>a .border-bottom,
  .dropdown:focus-within>a .border-bottom {
    width: 100% !important;
  }

  .dropdown:hover>a .border-left,
  .dropdown:hover>a .border-right,
  .dropdown:focus-within>a .border-left,
  .dropdown:focus-within>a .border-right {
    height: 100% !important;
  }

  .dropdown:hover>a .border-top-left,
  .dropdown:hover>a .border-top-right,
  .dropdown:focus-within>a .border-top-left,
  .dropdown:focus-within>a .border-top-right {
    transform: scaleX(1) !important;
  }

  .dropdown:hover>a .background-fill,
  .dropdown:focus-within>a .background-fill {
    transform: scale(1) !important;
  }

  .App-header.scrolled {
    display: flex;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 15%;
    z-index: 100;
    height: 62px;
    background-color: #F0F0F0;
    border-bottom: 4px solid rgb(0, 0, 128);
    position: fixed;
    left: 0;
    top: 0;
  }

  

  .App-header.scrolled .App-header-reposition {
    display: flex;
    width: 100%;
    position: absolute;
    left: 13px;
    display: flex;
    flex: 1;
    align-items: flex-start;
    transform: translateY(-50%);
    top: 50%;
    /* background-color: #004182; */
  }

  .App-header.scrolled .header-section-2 h2 {
    display: none;
  }

  .App-header.scrolled .header-section-1 img {
    height: 38px;
  }


  .App-header.scrolled .header-nav-links li {
    position: relative;
    top: 6.8px;
    /* top: 15px; */
  }

  .App-header.scrolled .header-nav-links li a {
    padding: 10px 10px;
    color: rgb(0, 0, 128);
    background-color: transparent;
    text-decoration: none;
    font-size: 1rem;
    position: relative;
    bottom: -1px;
  }

  .App-header.scrolled .header-nav-links li a::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 20px;
    height: 2px;
    transform-origin: center;
    transform: translateX(-50%) scaleX(1);
    transition: width 0.2s ease-in-out;
  }

  .App-header.scrolled .header-nav-links li a .border-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: white;
    transform: translateX(-50%);
    transition: width 0.15s ease-in-out;
  }

  .App-header.scrolled .header-nav-links li a:hover::after {
    width: 100%;
  }

  .App-header.scrolled .dropdown-menu li a {
    padding: 8px 10px;
    margin-top: 8px;
    color: rgb(0, 0, 128) !important;
    background-color: rgba(245, 245, 245, 1);
  }

  .App-header.scrolled .dropdown-menu li a:hover {
    background-color: rgb(0, 0, 110, 1);
    color: whitesmoke !important;
    transition: transform 0.1s ease-in-out !important;


  }

  .header-nav-links li a.active {
    color: rgb(0, 0, 128);
    font-weight: bold;
  }

  .header-nav-links li a.active .background-fill {
    transform: scale(1);
  }

  .header-nav-links li a:not(.active):hover .border-bottom {
    width: 100%;
  }

  .header-nav-links li a.active:hover .border-left,
  .header-nav-links li a.active:hover .border-right,
  .header-nav-links li a.active:hover .border-top-left,
  .header-nav-links li a.active:hover .border-top-right {
    display: none;
  }

  .header-social-icons {
    display: flex;
    position: absolute;
    right: 30px;
    top: 50%;
    /* Center the icon vertically relative to the parent */
    transform: translateY(-47%);
    /* Adjust for the element's height */
    align-items: center;
  }

  .header-social-icons a {
    color: #0A66C2;
    font-size: 32px !important;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
  }

  .header-social-icons a:hover {
    color: #004182;
    transform: scale(1.2);
  }

  .mobile-menu-toggle {
    display: none;
  }




  @media screen and (max-width: 768px) {
    .App-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 65px;
      z-index: 9;
      display: flex;
      margin: 0;
      justify-content: space-between;
      align-items: center;
      transition: background-color 0.5s ease-in-out;
      z-index: 20;
    }

    .App-header.scrolledMobile {
      background-color: #F0F0F0;
      box-sizing: border-box;
      transition: background-color 0.5s ease-in-out;
    }

    .App-header-reposition {
      position: static;
      left: auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transform: none;
      top: auto;
      margin: 0;
      padding: 0 18px;
    }

    .header-section-1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;
    }

    .header-section-1 img {
      height: 46px;
      width: auto;
    }

    .header-section-2 h2 {
      display: none;
    }

    .header-nav-links {
      align-items: center;
      display: flex;
      /* Keep this to maintain layout */
      flex-direction: column;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgb(0, 0, 128);
      z-index: 9999;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
      transition: transform 0.5s ease, opacity 0.5s ease, visibility 0s linear 0.5s;
    }

    .header-nav-links li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .header-nav-links li a {
      display: block;
      text-decoration: none;
      color: white;
      padding: 16px;
      text-align: center;
      background-color: rgb(0, 0, 128);
      font-size: 1.1rem !important;

    }

    .header-nav-links li a:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .header-nav-links li:last-child a {
      background-color: #0A66C2;
      color: #fff;
      /* Text color */
      font-weight: bold;
      padding: 15px 15px;
      display: inline-block;
      margin: 0 auto;
      margin-top: 15px;
      text-align: center;
    }

    .header-nav-links li a .border-bottom,
    .header-nav-links li a .border-left,
    .header-nav-links li a .border-right,
    .header-nav-links li a .border-top-left,
    .header-nav-links li a .border-top-right,
    .header-nav-links li a .background-fill {
      display: none;
    }


    .header-nav-links li a.active .background-fill {
      transform: scale(1);
    }

    .header-nav-links li a.active {
      color: white;
      font-weight: bold;
    }

    .header-nav-links li a:not(.active):hover .border-bottom {
      width: 100%;
    }

    .header-nav-links li a.active:hover .border-left,
    .header-nav-links li a.active:hover .border-right,
    .header-nav-links li a.active:hover .border-top-left,
    .header-nav-links li a.active:hover .border-top-right {
      display: none;
    }

    .dropdown-menu {
      display: none !important;
    }

    .hamburger-icon {
      display: block;
      width: 36px;
      height: 36px;
      cursor: pointer;
      position: absolute;
      right: 10.5px;
      top: 48%;
      transform: translateY(-50%);
      z-index: 10001;
      padding: 5px 10px;
    }

    .hamburger-icon span {
      display: block;
      width: 100%;
      height: 5px;
      margin: 5.5px 0;
      background: rgb(0, 0, 108);
      border-radius: 2px;
      transition: 0.4s;
    }

    .hamburger-icon {
      -webkit-tap-highlight-color: transparent;
    }

    .hamburger-icon:focus {
      outline: none;
      /* Removes the focus outline effect */
    }

    #mobile-menu-toggle {
      display: none;
    }

    #mobile-menu-toggle:checked~nav .header-nav-links {
      opacity: 1;
      visibility: visible;
      /* Make it interactable */
      transform: translateY(0);
      /* Bring it into view */
      transition: transform 0.5s ease, opacity 0.5s ease;
      /* Smooth fade and slide-in */
      height: 100vh;
      max-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 40px;
      transition-delay: 0s;
    }

    #mobile-menu-toggle:checked~nav .header-nav-links a {
      position: relative;
      /* top: 40px; */
      font-weight: bold;
      text-align: center;
      /* Ensure text is centered */
    }

    #mobile-menu-toggle:checked~nav .header-nav-links a.active {
      color: #0A66C2;
      font-weight: bold;
      transition: all 0.3s ease;
    }

    #mobile-menu-toggle:not(:checked)~nav .header-nav-links {
      transform: translateY(-100%);
      opacity: 0;
      visibility: hidden;
      transition: transform 0.5s ease, opacity 0.5s ease, visibility 0s linear 0.5s;
    }

    #mobile-menu-toggle:checked~label.hamburger-icon span {
      background: white;
    }

    #mobile-menu-toggle:checked~label.hamburger-icon span:nth-child(1) {
      transform: translateY(10.5px) rotate(135deg);
    }

    #mobile-menu-toggle:checked~label.hamburger-icon span:nth-child(2) {
      opacity: 0;
    }

    #mobile-menu-toggle:checked~label.hamburger-icon span:nth-child(3) {
      transform: translateY(-10px) rotate(-135deg);
    }

    .header-social-icons {
      display: none;
    }
  }



/* Remove transition delays for dropdown menu items when the "Commodity" link is active */
.dropdown > a.active[href="/goods-and-products"] + .dropdown-menu {
  transition-delay: 0s !important;
}

.dropdown > a.active[href="/types-of-freight"] + .dropdown-menu {
  transition-delay: 0s !important;
}

.dropdown > a.active[href="/our-services"] + .dropdown-menu {
  transition-delay: 0s !important;
}